import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class ObservationService {
	getObservation(patientID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/get_observation';
		const formData = new FormData();
		formData.append('patientID', patientID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}

	saveObservation(stateObj,postObj,patientID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/add_observation';
		const formData = new FormData();
		formData.append('patientID',patientID);
		formData.append('chief_complaint',stateObj.chiefComplaint);
		formData.append('chief_complaint_notes',postObj.chiefComplaintNotes);
		formData.append('history_notes',postObj.historyNotes);
		formData.append('examination_notes',postObj.examinationNotes);
		formData.append('smoking_history',postObj.smoking ? 'Yes' : 'No' );
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	deleteObservation(recordID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = '/api/del_observation';
		const formData = new FormData();
		formData.append('id',recordID);
		formData.append('status','Delete');
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}