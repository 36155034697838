
import { Options, Vue } from "vue-class-component";
import ReceiptService from "../../service/ReceiptService.js";
import ProfilerService from "../../service/ProfilerService.js";
import ChartService from "../../service/ChartService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import SearchFilter from "../../components/SearchFilter.vue";
import PreviewAccountingReceipt from "../../components/PreviewAccountingReceipt.vue";

@Options({
  components: {
    AutoComplete,
    SearchFilter,
    PreviewAccountingReceipt,
  },
})
export default class Invoice extends Vue {
  private lists = [];
  private PaymentLists = [
    {
      createdDate: "",
      updatedDate: "",
      receiptNo: "",
      receiptDate: "",
      totalAmount: 0,
      description: "",
      transactionType: "",
      type: "",
    },
  ];
  private profilerList = [];
  private accountHeadList = [];
  private bankList = [
    {
      bank: "None",
      id: 0,
    },
  ];

  private statement = "";
  private dialogTitle = "";
  private toast;
  private goToFirstLink = 0;
  private previewHeading = "";
  private receiptID = 0;
  private receiptService;
  private profilerService;
  private chartService;
  private previewImageDialog = false;
  private previewTransactionDialog = false;
  private productDialog = false;
  private filterDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Invoice" },
  ];

  private itemFilter = {
    keyword: "",
    filterType: "None",
    storeID: 0,
    date1: "",
    date2: "",
    type: "INV",
  };

  private taxNames = [
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
  ];

  private state = reactive({
    description: "",
    receiptDate: "",
    receiptDueDate: "",
    selectedProfile: "",
    itemList: [
      {
        accountID: 0,
        accountHead: "",
        quantity: 1,
        price: 0,
        discount: 0,
        subTotal: 0,
        tax1Value: this.taxNames[0].taxValue,
        tax2Value: this.taxNames[1].taxValue,
        tax3Value: this.taxNames[2].taxValue,
      },
    ],
  });

  private validationRules = {
    description: {
      required,
    },
    receiptDate: {
      required,
    },
    receiptDueDate: {
      required,
    },
    selectedProfile: {
      required,
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  private item = {
    id: 0,
    transactionID: 0,
    paymentType: "Pay Later",
    status: "Active",
    profileID: 0,
    type: "INV",
    totalTax1: 0,
    totalTax2: 0,
    totalTax3: 0,
    totalGross: 0,
    totalDiscount: 0,
    totalTax: 0,
    totalBill: 0,
    totalPaid: 0,
  };

  private counterEntry = [
    {
      accountID: 0,
      accountHead: "",
      amount: 0,
      type: "Debit",
    },
  ];

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.receiptService = new ReceiptService();
    this.profilerService = new ProfilerService();
    this.chartService = new ChartService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();
    this.submitted = false;
    this.dialogTitle = "Create Invoice";
    this.productDialog = true;
  }

  openFilterDialog() {
    this.dialogTitle = "Filter Invoice";
    this.filterDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid && this.validateHeadList.length == 0) {
      this.setAccountingEntries();

      if (this.item.id != 0) {
        this.state.receiptDate = moment(this.state.receiptDate).format(
          "YYYY-MM-DD"
        );

        this.state.receiptDueDate = moment(this.state.receiptDueDate).format(
          "YYYY-MM-DD"
        );

        this.receiptService
          .updateInvoice(this.item, this.state, this.counterEntry)
          .then((res) => {
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      } else {
        this.state.receiptDate = moment(this.state.receiptDate).format(
          "YYYY-MM-DD"
        );

        this.state.receiptDueDate = moment(this.state.receiptDueDate).format(
          "YYYY-MM-DD"
        );

        this.receiptService
          .saveInvoice(this.item, this.state, this.counterEntry)
          .then((res) => {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Invoice";
    this.productDialog = true;

    this.receiptService.getItem(data).then((res) => {
      if (res != null) {
        this.item.id = Number(res.receipt[0].id);
        this.item.transactionID = Number(res.receipt[0].transaction_id);
        this.item.status = res.receipt[0].status;
        this.item.profileID = Number(res.receipt[0].profile_id);
        this.item.type = res.receipt[0].type;
        this.item.totalTax1 = Number(res.receipt[0].total_tax1);
        this.item.totalTax2 = Number(res.receipt[0].total_tax2);
        this.item.totalTax3 = Number(res.receipt[0].total_tax3);
        this.item.totalGross = Number(res.receipt[0].gross_total);
        this.item.totalDiscount = Number(res.receipt[0].total_discount);
        this.item.totalTax = Number(res.receipt[0].total_tax);
        this.item.totalBill = Number(res.receipt[0].total_bill);

        this.item.totalPaid = this.calculatePaid(
          data.cash_transactions,
          data.bank_transactions
        );

        this.state.description = res.receipt[0].description;
        this.state.receiptDate = res.receipt[0].receipt_date;
        this.state.receiptDueDate = res.receipt[0].receipt_due_date;
        this.state.selectedProfile = res.receipt[0].profile_name.profileName;

        let vList = res.subReceipt;

        if (vList.length > 0) {
          this.state.itemList = [];
          vList.map((v) => {
            this.state.itemList.push({
              accountID: Number(v.sub_transaction_id),
              accountHead: v.chart_name.chartName,
              quantity: Number(v.qty),
              price: Number(v.price),
              discount: Number(v.discount),
              subTotal: Number(v.sub_total),
              tax1Value: Number(v.tax1),
              tax2Value: Number(v.tax2),
              tax3Value: Number(v.tax3),
            });
          });
        }
      }
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.receiptService.getItems(this.itemFilter, page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
      this.statement = data.statement;

      // //taxNames
      this.taxNames = [];

      this.taxNames.push({
        taxName: data.storeTaxes[0].tax_name_1,
        show: data.storeTaxes[0].show_1,
        optionalReq: data.storeTaxes[0].required_optional_1,
        taxValue:
          data.storeTaxes[0].show_1 == "true"
            ? Number(data.storeTaxes[0].tax_value_1)
            : 0,
        accountHead: data.storeTaxes[0].tax_name1.chartName,
        accountID: data.storeTaxes[0].link1,
      });

      this.taxNames.push({
        taxName: data.storeTaxes[0].tax_name_2,
        show: data.storeTaxes[0].show_2,
        optionalReq: data.storeTaxes[0].required_optional_2,
        taxValue:
          data.storeTaxes[0].show_2 == "true"
            ? Number(data.storeTaxes[0].tax_value_2)
            : 0,
        accountHead: data.storeTaxes[0].tax_name2.chartName,
        accountID: data.storeTaxes[0].link2,
      });

      this.taxNames.push({
        taxName: data.storeTaxes[0].tax_name_3,
        show: data.storeTaxes[0].show_3,
        optionalReq: data.storeTaxes[0].required_optional_3,
        taxValue:
          data.storeTaxes[0].show_3 == "true"
            ? Number(data.storeTaxes[0].tax_value_3)
            : 0,
        accountHead: data.storeTaxes[0].tax_name3.chartName,
        accountID: data.storeTaxes[0].link3,
      });
    });
  }

  clearItem() {
    this.item.id = 0;
    this.item.profileID = 0;
    this.item.status = "Active";
    this.state.description = "";
    this.state.receiptDate = "";
    this.state.receiptDueDate = "";
    this.state.selectedProfile = "";

    this.state.itemList = [];
    this.state.itemList.push({
      accountID: 0,
      accountHead: "",
      quantity: 1,
      price: 0,
      discount: 0,
      subTotal: 0,
      tax1Value: this.taxNames[0].taxValue,
      tax2Value: this.taxNames[1].taxValue,
      tax3Value: this.taxNames[2].taxValue,
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.itemFilter.keyword) {
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
  }

  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 500);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.state.selectedProfile = profileInfo.account_title;
    this.item.profileID = profileInfo.id;
  }

  searchAccountHead(event) {
    setTimeout(() => {
      this.chartService.searchAccountHead(event.query.trim()).then((data) => {
        this.accountHeadList = data.records;
      });
    }, 500);
  }

  saveAccountHead(event, data) {
    const accountInfo = event.value;
    data.accountHead = accountInfo.account_name;
    data.accountID = accountInfo.id;
  }

  addNewLine() {
    this.state.itemList.push({
      accountID: 0,
      accountHead: "",
      quantity: 1,
      price: 0,
      discount: 0,
      subTotal: 0,
      tax1Value: this.taxNames[0].taxValue,
      tax2Value: this.taxNames[1].taxValue,
      tax3Value: this.taxNames[2].taxValue,
    });
  }

  clearLines() {
    this.state.itemList = [];
    this.state.itemList.push({
      accountID: 0,
      accountHead: "",
      quantity: 1,
      price: 0,
      discount: 0,
      subTotal: 0,
      tax1Value: this.taxNames[0].taxValue,
      tax2Value: this.taxNames[1].taxValue,
      tax3Value: this.taxNames[2].taxValue,
    });

    this.toast.showSuccess("All Items Deleted Successfully");
  }

  clearListItem(item) {
    this.state.itemList.splice(this.state.itemList.indexOf(item), 1);
    this.toast.showSuccess("Item Deleted Successfully");
  }

  get validateHeadList() {
    let invalidListItems: Number[] = [];

    this.state.itemList.map((v, index) => {
      if (v.accountID == 0) {
        invalidListItems.push(index);
      }
    });

    return invalidListItems;
  }

  fixDigits(amt) {
    let total = 0;
    amt = Number(amt);
    total = amt.toFixed(2);
    return total;
  }

  formatDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  formatDateTime(date) {
    return moment(date).format("DD-MM-YYYY hh:mm A");
  }

  updateFilterStatus(obj) {
    if (obj != null && obj.loading == "Yes") {
      this.itemFilter.filterType = obj.filterName.value;
      this.itemFilter.storeID = obj.storeID.id;
      this.itemFilter.date1 = obj.date1;
      this.itemFilter.date2 = obj.date2;
      this.itemFilter.keyword = "";
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
    this.filterDialog = false;
  }

  openPreviewDialog(data) {
    this.previewImageDialog = true;
    this.dialogTitle = "Preview Invoice";
    this.previewHeading = "Invoice";
    this.receiptID = data.id;
  }

  updatePreviewStatus() {
    this.previewImageDialog = false;
  }

  getTheSubtotal(data) {
    const qty = Number(data.quantity);
    const price = Number(data.price);
    const discount = Number(data.discount);
    const tax1 = Number(data.tax1Value);
    const tax2 = Number(data.tax2Value);
    const tax3 = Number(data.tax3Value);

    const total = qty * price;
    const disAmount = (total / 100) * discount;

    const afterDis = total - disAmount;
    const afterTax = (afterDis / 100) * (tax1 + tax2 + tax3);

    const netTotal = afterDis + afterTax;

    data.subTotal = Number(this.fixDigits(netTotal));

    return Number(this.fixDigits(netTotal));
  }

  getTheTotalAfterDisc(data) {
    const qty = Number(data.quantity);
    const price = Number(data.price);
    const discount = Number(data.discount);

    const total = qty * price;
    const disAmount = (total / 100) * discount;

    const afterDis = total - disAmount;

    return Number(this.fixDigits(afterDis));
  }

  get totalGross() {
    let total = 0;
    this.state.itemList.forEach((e) => {
      total = total + e.price * e.quantity;
    });

    return total;
  }

  get totalTax1() {
    let total = 0;
    this.state.itemList.forEach((e) => {
      const price = e.price * e.quantity;
      const afterDisc = (price / 100) * e.discount;
      total = total + ((price - afterDisc) / 100) * e.tax1Value;
    });

    return Number(total.toFixed(2));
  }

  get totalTax2() {
    let total = 0;
    this.state.itemList.forEach((e) => {
      const price = e.price * e.quantity;
      const afterDisc = (price / 100) * e.discount;
      total = total + ((price - afterDisc) / 100) * e.tax2Value;
    });

    return Number(total.toFixed(2));
  }

  get totalTax3() {
    let total = 0;
    this.state.itemList.forEach((e) => {
      const price = e.price * e.quantity;
      const afterDisc = (price / 100) * e.discount;
      total = total + ((price - afterDisc) / 100) * e.tax3Value;
    });

    return Number(total.toFixed(2));
  }

  get totalDiscAmount() {
    let total = 0;
    this.state.itemList.forEach((e) => {
      const price = e.price * e.quantity;
      total = total + (price / 100) * e.discount;
    });

    return total;
  }

  get netTotal() {
    return Number(
      (
        this.totalGross -
        this.totalDiscAmount +
        this.totalTax1 +
        this.totalTax2 +
        this.totalTax3
      ).toFixed(2)
    );
  }

  get totalTax() {
    return Number(
      (this.totalTax1 + this.totalTax2 + this.totalTax3).toFixed(2)
    );
  }

  setAccountingEntries() {
    this.item.totalTax1 = this.totalTax1;
    this.item.totalTax2 = this.totalTax2;
    this.item.totalTax3 = this.totalTax3;
    this.item.totalGross = this.totalGross;
    this.item.totalDiscount = this.totalDiscAmount;
    this.item.totalTax = this.totalTax;
    this.item.totalBill = this.netTotal;

    this.counterEntry = [];
    this.counterEntry.push({
      accountID: 4,
      accountHead: "Accounts receivable",
      amount: this.netTotal,
      type: "Debit",
    });

    this.state.itemList.forEach((e) => {
      this.counterEntry.push({
        accountID: e.accountID,
        accountHead: e.accountHead,
        amount: this.getTheTotalAfterDisc(e),
        type: "Credit",
      });
    });

    //ADDING TAXES
    if (this.totalTax1 != 0) {
      this.counterEntry.push({
        accountID: this.taxNames[0].accountID,
        accountHead: this.taxNames[0].accountHead,
        amount: this.totalTax1,
        type: "Credit",
      });
    }

    if (this.totalTax2 != 0) {
      this.counterEntry.push({
        accountID: this.taxNames[1].accountID,
        accountHead: this.taxNames[1].accountHead,
        amount: this.totalTax2,
        type: "Credit",
      });
    }

    if (this.totalTax3 != 0) {
      this.counterEntry.push({
        accountID: this.taxNames[2].accountID,
        accountHead: this.taxNames[2].accountHead,
        amount: this.totalTax3,
        type: "Credit",
      });
    }
  }

  calculateBalance(totalBill, cashTransactions, bankTransactions) {
    let totalAmount = 0;

    cashTransactions.forEach((e) => {
      totalAmount = totalAmount + Number(e.total_amount);
    });

    bankTransactions.forEach((e) => {
      totalAmount = totalAmount + Number(e.amount);
    });

    return Number((totalBill - totalAmount).toFixed(2));
  }

  calculatePaid(cashTransactions, bankTransactions) {
    let totalAmount = 0;

    cashTransactions.forEach((e) => {
      totalAmount = totalAmount + Number(e.total_amount);
    });

    bankTransactions.forEach((e) => {
      totalAmount = totalAmount + Number(e.amount);
    });

    return Number(totalAmount.toFixed(2));
  }

  openTransactionDialog(data) {
    this.previewTransactionDialog = true;
    this.PaymentLists = [];

    data.cash_transactions.forEach((e) => {
      this.PaymentLists.push({
        createdDate: e.created_at,
        updatedDate: e.updated_at,
        receiptNo: e.voucher_no,
        receiptDate: e.voucher_date,
        totalAmount: e.total_amount,
        description: e.memo,
        transactionType: e.account_type,
        type: e.type,
      });
    });

    data.bank_transactions.forEach((e) => {
      this.PaymentLists.push({
        createdDate: e.created_at,
        updatedDate: e.updated_at,
        receiptNo: e.receipt_no,
        receiptDate: e.receipt_date,
        totalAmount: e.amount,
        description: e.description,
        transactionType: e.transaction_no,
        type: e.type,
      });
    });
  }
}
