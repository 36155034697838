<template>
    <section>
        <div class="app-container">
            <Toolbar>
                <template #start>
                    <Breadcrumb
                        :home="home"
                        :model="items"
                        class="p-menuitem-text"
                    />
                </template>

                <template #end>
                    <div class="p-mx-2">
                        <div class="p-field">
                            <AutoComplete
                                :delay="1000"
                                :minLength="3"
                                @item-select="savePatient($event)"
                                scrollHeight="500px"
                                v-model="item.selectedProfile"
                                :suggestions="patientList"
                                placeholder="Search Patient"
                                @complete="searchPatient($event)"
                                :dropdown="false"
                                class="p-mt-3"
                                autoFocus
                            >
                                <template #item="slotProps">
                                    <div>
                                        Name :
                                        <b class="pull-right">
                                            {{
                                                slotProps.item.first_name.toUpperCase()
                                            }}
                                            {{
                                                slotProps.item.last_name.toUpperCase()
                                            }}
                                        </b>
                                    </div>
                                    <div>
                                        Gender :
                                        <span class="pull-right">
                                            {{ slotProps.item.gender }}
                                        </span>
                                    </div>
                                    <div>
                                        ID :
                                        <span class="pull-right">
                                            {{ slotProps.item.patient_no }}
                                        </span>
                                    </div>
                                    <div>
                                        Phone No :
                                        <span class="pull-right">
                                            {{ slotProps.item.phone_no }}
                                        </span>
                                    </div>
                                    <div>
                                        Age :
                                        <span class="pull-right">
                                            {{ patientAge(slotProps.item.dob) }}
                                        </span>
                                    </div>
                                    <div>
                                        Date of Birth :
                                        <span class="pull-right">
                                            {{ formatDate(slotProps.item.dob) }}
                                        </span>
                                    </div>
                                </template>
                            </AutoComplete>
                        </div>
                    </div>
                    <div class="p-mx-2">
                        <Button
                            icon="pi pi-search"
                            label="Search"
                            class="p-button-danger p-mx-1"
                            @click="searchPatientID"
                        />
                        <Button
                            icon="pi pi-plus"
                            label="New Visit"
                            class="p-button-success p-mx-1"
                            @click="openVisitDialog"
                        />
                        <Button
                            icon="pi pi-plus"
                            label="New Patient"
                            class="p-button-primary p-mx-1"
                            @click="openDialog"
                        />
                        <Button
                            icon="pi pi-pencil"
                            label="Edit Patient"
                            class="p-button-secondary p-mx-1"
                            @click="editPatient"
                        />
                        <Button
                            icon="pi pi-search"
                            label="Filter Visits"
                            class="p-button-warning p-mx-1"
                            @click="openFilterDialog"
                        />
                    </div>
                </template>
            </Toolbar>
            <p class="p-text-center p-mt-2 p-mb-0">
                {{ statement }}
            </p>
            <div class="p-mt-2">
                <DataTable
                    v-model:first.sync="goToFirstLink"
                    :value="lists"
                    :lazy="true"
                    :paginator="checkPagination"
                    :rows="limit"
                    :totalRecords="totalRecords"
                    :scrollable="true"
                    @page="onPage($event)"
                    class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
                >
                    <template #empty>
                        <div class="p-text-center p-p-3">No records found</div>
                    </template>
                    <Column
                        field="patient_details.patient_no"
                        header="Patient ID"
                    ></Column>
                    <Column header="Patient Name">
                        <template #body="slotProps">
                            {{ slotProps.data.patient_details.first_name }}
                            {{ slotProps.data.patient_details.last_name }}
                        </template>
                    </Column>
                    <Column
                        field="patient_details.phone_no"
                        header="Phone NO"
                    ></Column>
                    <Column
                        field="patient_details.gender"
                        header="Gender"
                    ></Column>
                    <Column field="doctor_name" header="Doctor Name"></Column>
                    <Column header="Visit Date/Time">
                        <template #body="slotProps">
                            {{ formatDateTime(slotProps.data.visit_date) }}
                        </template>
                    </Column>
                    <Column field="visit_fee" header="Visit Fee"></Column>
                    <Column :exportable="false" header="Action">
                        <template #body="slotProps">
                            <Button
                                icon="pi pi-pencil"
                                class="p-button-rounded p-button-primary p-mr-2"
                                @click="editIem(slotProps.data)"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <Dialog
                v-model:visible="productDialog"
                :style="{ width: '50vw' }"
                :maximizable="true"
                position="top"
                :closable="true"
                class="p-fluid"
                @hide="closeDialog"
            >
                <template #header>
                    <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
                        {{ dialogTitle }}
                    </h5>
                </template>
                <div class="p-field">
                    <label for="patientName">Patient Name</label>
                    <AutoComplete
                        :delay="1000"
                        :minLength="3"
                        @item-select="saveVisitPatient($event)"
                        scrollHeight="500px"
                        v-model="v$.selectedProfile.$model"
                        :suggestions="patientList"
                        placeholder="Search Patient"
                        @complete="searchPatient($event)"
                        :dropdown="false"
                        autoFocus
                    >
                        <template #item="slotProps">
                            <div>
                                Name :
                                <b class="pull-right">
                                    {{
                                        slotProps.item.first_name.toUpperCase()
                                    }}
                                    {{ slotProps.item.last_name.toUpperCase() }}
                                </b>
                            </div>
                            <div>
                                Gender :
                                <span class="pull-right">
                                    {{ slotProps.item.gender }}
                                </span>
                            </div>
                            <div>
                                Phone No :
                                <span class="pull-right">
                                    {{ slotProps.item.phone_no }}
                                </span>
                            </div>
                            <div>
                                Age :
                                <span class="pull-right">
                                    {{ patientAge(slotProps.item.dob) }}
                                </span>
                            </div>
                            <div>
                                Date of Birth :
                                <span class="pull-right">
                                    {{ formatDate(slotProps.item.dob) }}
                                </span>
                            </div>
                        </template>
                    </AutoComplete>
                    <small
                        v-if="
                            (v$.selectedProfile.$invalid && submitted) ||
                                v$.selectedProfile.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.selectedProfile.required.$message.replace(
                                "Value",
                                "Patient Name"
                            )
                        }}</small
                    >
                </div>
                <div class="p-field">
                    <label for="visitDate">Visit Date & Time</label>
                    <Calendar
                        v-model="v$.visitDate.$model"
                        :touchUI="true"
                        dateFormat="dd-mm-yy"
                        hourFormat="12"
                        :showTime="true"
                    />
                    <small
                        v-if="
                            (v$.visitDate.$invalid && submitted) ||
                                v$.visitDate.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.visitDate.required.$message.replace(
                                "Value",
                                "Visit Date/Time"
                            )
                        }}</small
                    >
                </div>
                <div class="p-field">
                    <label for="visitDate">Visit Fee</label>
                    <InputNumber
                        :disabled="true"
                        :maxFractionDigits="2"
                        :minFractionDigits="2"
                        v-model="item.visitFee"
                    />
                </div>
                <div class="p-field">
                    <label for="doctorName">Doctor Name</label>
                    <InputText id="doctorName" v-model.trim="item.doctorName" />
                </div>
                <div class="p-field">
                    <label for="notes">Notes</label>
                    <InputText id="notes" v-model="item.notes" />
                </div>
                <template #footer>
                    <Button
                        type="submit"
                        label="Save"
                        icon="pi pi-check"
                        class="p-button-primary"
                        @click.prevent="saveVisitItem(!v$.$invalid)"
                    />
                </template>
            </Dialog>
        </div>
    </section>

    <!-- <PatientDialog
        :PatientRegistration="{
            patientID: this.patientID,
            status: this.patientDialogStatus,
            dialogTitle: this.dialogTitle
        }"
        v-on:updatePatientStatus="updatePatientStatus"
    /> -->

    <component
        v-if="componentName != ''"
        v-bind:is="componentName"
        v-on:updatePatientStatus="updatePatientStatus"
    >
    </component>

    <SearchFilter
        :searchDetail="{
            status: this.filterDialog,
            dialogTitle: this.dialogTitle
        }"
        v-on:updateFilterStatus="updateFilterStatus"
    />
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import VisitService from "../../service/VisitService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import PatientDialog from "../../components/PatientDialog.vue";
import SearchFilter from "../../components/SearchFilter.vue";
import AutoComplete from "primevue/autocomplete";
import moment from "moment";
import { useStore, ActionTypes } from "../../store";

@Options({
    components: {
        PatientDialog,
        SearchFilter,
        AutoComplete
    }
})
export default class EncounterVisit extends Vue {
    private store = useStore();
    private componentName = "";
    private lists = [];
    private dialogTitle = "";
    private keyword = "";
    private filterDialog = false;
    private toast;
    private goToFirstLink = 0;
    private statement = "";
    private patientID = 0;
    private visitService;
    private productDialog = false;
    private submitted = false;
    private checkPagination = true;
    private totalRecords = 0;
    private limit = 0;
    private home = { icon: "pi pi-home", to: "/" };
    private storeList = [];
    private patientList = [];
    private items = [{ label: "Front Desk" }];

    private selectedStore = {
        id: 0
    };

    private item = {
        id: 0,
        selectedProfile: "",
        doctorName: "",
        notes: "",
        patientID: 0,
        visitFee: 0
    };

    private itemFilter = {
        keyword: "",
        filterType: "None",
        storeID: 0,
        date1: "",
        date2: "",
        type: "Encounter"
    };

    private state = reactive({
        visitDate: "",
        selectedProfile: ""
    });

    private validationRules = {
        visitDate: {
            required
        },
        selectedProfile: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    //CALLING WHEN PAGINATION BUTTON CLICKS
    onPage(event) {
        this.loadList(event.first);
    }

    //DEFAULT METHOD OF TYPE SCRIPT
    created() {
        this.visitService = new VisitService();
        this.toast = new Toaster();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.loadList(0);
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.clearItem();
        this.componentName = "PatientDialog";
        this.store.dispatch(ActionTypes.GET_RECEIPT_ID, "0");
    }

    openVisitDialog() {
        if (this.item.patientID == 0 || this.item.selectedProfile == "") {
            this.toast.showError("Please choose any Patients Profile");
        } else {
            this.state.selectedProfile = this.item.selectedProfile;
            this.dialogTitle =
                "Schedule Appointment for " + this.item.selectedProfile;
            this.productDialog = true;
        }
    }

    editPatient() {
        if (this.item.patientID == 0 || this.item.selectedProfile == "") {
            this.toast.showError("Please choose any Patients Profile");
        } else {
            this.componentName = "PatientDialog";
            this.store.dispatch(
                ActionTypes.GET_RECEIPT_ID,
                String(this.item.patientID)
            );
        }
    }

    //CLOSE THE ITEM DAILOG BOX
    hideDialog() {
        this.productDialog = false;
        this.submitted = false;
    }

    //ADD OR UPDATE THE ITEM VIA HTTP
    saveVisitItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            this.state.visitDate = this.formatDateTimeReverse(
                this.state.visitDate
            );
            if (this.item.id != 0) {
                this.visitService
                    .updateItem(this.item, this.state)
                    .then(res => {
                        this.loadList(this.goToFirstLink);
                        //SHOW NOTIFICATION
                        this.toast.handleResponse(res);
                    });
            } else {
                this.visitService.saveItem(this.item, this.state).then(res => {
                    this.goToFirstLink = 0;
                    this.loadList(this.goToFirstLink);
                    //SHOW NOTIFICATION
                    this.toast.handleResponse(res);
                });
            }

            this.productDialog = false;
            this.clearItem();
        }
    }

    //OPEN DIALOG BOX TO EDIT
    editIem(data) {
        this.submitted = false;
        this.dialogTitle =
            "Update Schedule Appointment for " +
            data.patient_details.first_name +
            " " +
            data.patient_details.last_name;
        this.productDialog = true;

        this.state.selectedProfile =
            data.patient_details.first_name +
            " " +
            data.patient_details.last_name;
        this.item.doctorName = data.doctor_name == null ? "" : data.doctor_name;
        this.item.notes = data.notes == null ? "" : data.notes;
        this.state.visitDate = this.formatDateTime(data.visit_date);
        this.item.patientID = data.patient_details.id;
        this.item.visitFee = Number(data.visit_fee);
        this.item.id = data.id;
    }

    //FETCH THE DATA FROM SERVER
    loadList(page) {
        this.visitService
            .getItems(this.itemFilter, this.item.patientID, page)
            .then(data => {
                this.lists = data.records;
                this.totalRecords = data.totalRecords;
                this.storeList = data.stores;
                this.limit = data.limit;
                this.statement = data.statement;
            });
    }

    clearItem() {
        this.item = {
            id: 0,
            selectedProfile: "",
            doctorName: "",
            notes: "",
            patientID: 0,
            visitFee: 0
        };

        this.state.visitDate = "";
        this.state.selectedProfile = "";
        this.submitted = false;
    }

    searchPatient(event) {
        setTimeout(() => {
            this.visitService.searchPatient(event.query.trim()).then(data => {
                this.patientList = data.records;
            });
        }, 200);
    }

    savePatient(event) {
        const profileInfo = event.value;
        this.item.selectedProfile =
            profileInfo.first_name + " " + profileInfo.last_name;
        this.item.patientID = profileInfo.id;
    }

    saveVisitPatient(event) {
        const profileInfo = event.value;
        this.state.selectedProfile =
            profileInfo.first_name + " " + profileInfo.last_name;
        this.item.patientID = profileInfo.id;
    }

    formatDate(d) {
        const dt = new Date(d);
        return moment(dt).format("DD-MMM-YYYY");
    }

    updatePatientStatus(obj) {
        console.log(obj);
        this.item.patientID = obj.patientID;
        this.item.selectedProfile = obj.patientFullName;
        this.componentName = "";
        this.loadList(this.goToFirstLink);
    }

    patientAge(d) {
        let age = "";
        const dt = new Date(d);
        const years = moment().diff(dt, "years", false);
        age = years + " Year";

        if (years == 0) {
            const months = moment().diff(dt, "months", false);
            age = months + " Month";

            if (months == 0) {
                const days = moment().diff(dt, "days", false);
                age = days + " Day";
            }
        }

        return age;
    }

    formatDateTime(dt) {
        return moment(new Date(dt)).format("DD-MMM-YYYY hh:mm A");
    }

    formatDateTimeReverse(dt) {
        return moment(new Date(dt)).format("YYYY-MM-DD  hh:mm");
    }

    openFilterDialog() {
        this.dialogTitle = "Filter Vouchers";
        this.filterDialog = true;
    }

    updateFilterStatus(obj) {
        if (obj != null && obj.loading == "Yes") {
            this.itemFilter.filterType = obj.filterName.value;
            this.itemFilter.storeID = obj.storeID.id;
            this.itemFilter.date1 = obj.date1;
            this.itemFilter.date2 = obj.date2;
            this.itemFilter.keyword = "";
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
        }
        this.filterDialog = false;
    }

    searchPatientID() {
        if (this.item.selectedProfile == "") {
            this.clearItem();
            this.loadList(0);
        } else {
            this.loadList(0);
        }
    }

    closeDialog() {
        this.productDialog = false;
        this.submitted = false;
        this.clearItem();
    }
}
</script>
