import moment from "moment";
import { Vue } from "vue-class-component";
import { camelCase } from "lodash";
import { useStore } from "../store";

// You can declare mixins as the same style as components.
export default class UtilityOptions extends Vue {

  protected store = useStore();

  formatDate(value) {
    let d = "";
    if (value) {
      d =  moment(value).format("DD-MM-YYYY");
    }
    return d;
  }

  get userPermission() {
    const p =  JSON.parse(this.store.getters.gerUserPermissions);
    return p;
  }

  getDayName(date)
  {
    let d = "";
    
    if(date != '')
    {
      d = moment(date).format('ddd');
    }

    return d;
  }

  formatTime(value) {
    let d = "";
    if (value) {
      d =  moment(value, "HH:mm").format("hh:mm A");
    }
    return d;
  }

  formatAmount(value) {
    value = Number(value);
    return value.toFixed(2);
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  can(permission)
  {
      const pL = this.userPermission;
      const f = pL.filter(e =>  e.name == permission);

      let res = false;

      if(f.length > 0)
      {
        res = true;
      }

      return res;
  }
}