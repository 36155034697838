
import { Options, Vue } from "vue-class-component";
import LabService from "../../service/LabService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";

interface optionListType {
    optionName: string;
    id: number;
}

@Options({
    components: {}
})
export default class LabList extends Vue {
    private lists = [];
    private dialogTitle;
    private keyword = "";
    private toast;
    private goToFirstLink = 0;
    private labService;
    private productDialog = false;
    private submitted = false;
    private statusDialog = false;
    private checkPagination = true;
    private totalRecords = 0;
    private limit = 0;
    private home = { icon: "pi pi-home", to: "/" };
    private optionList: optionListType[] = [];
    private items = [
        { label: "Initialization", to: "initialization" },
        { label: "Laboratory Tests" }
    ];

    private item = {
        id: 0,
        status: "Active"
    };

    private selectedOption = 0;

    private state = reactive({
        testName: "",
        categoryType: { id: 0, optionName: "" },
        charges: 0
    });

    private validationRules = {
        testName: {
            required
        },
        categoryType: {
            required
        },
        charges: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    //CALLING WHEN PAGINATION BUTTON CLICKS
    onPage(event) {
        this.loadList(event.first);
    }

    //DEFAULT METHOD OF TYPE SCRIPT
    created() {
        this.labService = new LabService();
        this.toast = new Toaster();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.loadList(0);
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.clearItem();

        this.submitted = false;
        this.dialogTitle = "Add New Test";
        this.productDialog = true;
    }

    //CLOSE THE ITEM DAILOG BOX
    hideDialog() {
        this.productDialog = false;
        this.submitted = false;
    }

    //ADD OR UPDATE THE ITEM VIA HTTP
    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            if (this.item.id != 0) {
                this.labService.updateItem(this.item, this.state).then(res => {
                    this.loadList(this.goToFirstLink);
                    //SHOW NOTIFICATION
                    this.toast.handleResponse(res);
                });
            } else {
                this.labService.saveItem(this.item, this.state).then(res => {
                    this.goToFirstLink = 0;
                    this.loadList(this.goToFirstLink);
                    //SHOW NOTIFICATION
                    this.toast.handleResponse(res);
                });
            }

            this.productDialog = false;
            this.clearItem();
        }
    }

    //OPEN DIALOG BOX TO EDIT
    editIem(data) {
        this.submitted = false;
        this.dialogTitle = "Update Test";
        this.productDialog = true;

        this.labService.getItem(data).then(res => {
            if (res.length > 0) {
                const charges = Number(res[0].charges);
                this.state.testName = res[0].name;
                this.state.charges = charges;
                this.state.categoryType = this.searchOption(res[0].type_id);
                this.item.status = res[0].status;
                this.item.id = res[0].id;
            }
        });
    }

    searchOption(id) {
        let obj = {
            id: 0,
            optionName: ""
        };

        this.optionList.forEach(e => {
            if (e.id == id) {
                obj = e;
            }
        });
        return obj;
    }

    //OPEN DIALOG BOX FOR CONFIRMATION
    confirmChangeStatus(data) {
        this.item.id = data.id;
        this.state.testName = data.name;
        this.statusDialog = true;
    }

    //CHANGE THE STATUS AND SEND HTTP TO SERVER
    changeStatus() {
        this.statusDialog = false;
        this.item.status = "Delete";
        this.labService.changeStatus(this.item).then(res => {
            this.loadList(0);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
        });
    }

    //FETCH THE DATA FROM SERVER
    loadList(page) {
        this.labService.getItems(this.selectedOption, page).then(data => {
            this.lists = data.records;
            this.totalRecords = data.totalRecords;
            const optionList = data.categories;
            this.limit = data.limit;

            optionList.forEach(e => {
                this.optionList.push({
                    optionName: e.option_name,
                    id: e.id
                });
            });
        });
    }

    clearItem() {
        this.item = {
            id: 0,
            status: "Active"
        };

        this.state.testName = "";
        this.state.categoryType = { id: 0, optionName: "" };
        this.state.charges = 0;
    }
}
