
import { Options, mixins } from "vue-class-component";
import UtilityOptions from "../../mixins/UtilityOptions";

export default class Initialization extends mixins(UtilityOptions) {
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Initialization" }];
  private initializationList = [
    {
      label: "Branches",
      image: require("@/assets/menu-icons/branch.png").default,
      to: "/store/stores",
      description:
        "Add and Manage stores. You can add new store or edit existing store details plus add setup store details.",
    },
    {
      label: "Users",
      image: require("@/assets/menu-icons/services.png").default,
      to: "/store/users",
      description:
        "Add and Manage services like Shirts, Dresses , Skirts to POS screen for your customers.",
    },
    {
      label: "Banks",
      image: require("@/assets/menu-icons/departments.png").default,
      to: "/store/banks",
      description:
        "Add and Manage departments like Buttons, Hems , Seams to POS Screen for your customers.",
    },
    {
      label: "Banking",
      image: require("@/assets/menu-icons/departments.png").default,
      to: "/store/banking",
      description:
        "Add and Manage departments like Buttons, Hems , Seams to POS Screen for your customers.",
    },
    {
      label: "Chart Of Accounts",
      image: require("@/assets/menu-icons/item-details.png").default,
      to: "/store/chart-of-accounts",
      description:
        "Add and Manage Extra item charges for item like if you want to add extra charge in pants services.",
    },
    {
      label: "Sale Services",
      image: require("@/assets/menu-icons/upchargelist.png").default,
      to: "/store/sale-services",
      description:
        "Add extra charges upon specific services like 10% on Express.You can add other item tags here and manage those tags.",
    },
    {
      label: "Option Tags",
      image: require("@/assets/menu-icons/items.png").default,
      to: "/store/option-tag",
      description:
        "Set colors, conditions and other tags like Male, Female, Red , Broken etc.You can create and manage existing tag..",
    },
    {
      label: "Payment Methods",
      image: require("@/assets/menu-icons/items.png").default,
      to: "/store/payment-method",
      description:
        "Set colors, conditions and other tags like Male, Female, Red , Broken etc.You can create and manage existing tag..",
    },
    {
      label: "Journal Voucher",
      image: require("@/assets/menu-icons/corporate_heads.png").default,
      to: "/store/journal-voucher",
      description:
        "Add and Manage coupons/discount for customers. Once you add from here it will appear on POS screen.",
    },
     {
      label: "Other Voucher",
      image: require("@/assets/menu-icons/corporate_heads.png").default,
      to: "/store/other-voucher",
      description:
        "Debit Voucher is used when paying cash.",
    },
    {
      label: "User Balance",
      image: require("@/assets/menu-icons/corporate_heads.png").default,
      to: "/store/user-balance",
      description:
        "Voucher is used when receiving cash.",
    },
    {
      label: "Sale Services",
      image: require("@/assets/menu-icons/corporate_heads.png").default,
      to: "/store/sale-services",
      description:
        "Voucher is used when receiving cash.",
    },
    {
      label: "Open Head",
      image: require("@/assets/menu-icons/corporate_heads.png").default,
      to: "/store/open-head",
      description:
        "Voucher is used when receiving cash.",
    },
    {
      label: "Invoice",
      image: require("@/assets/menu-icons/corporate_heads.png").default,
      to: "/store/invoice",
      description:
        "Voucher is used when receiving cash.",
    },
    {
      label: "Sales/Refund Receipt",
      image: require("@/assets/menu-icons/corporate_heads.png").default,
      to: "/store/sales-receipt",
      description:
        "Voucher is used when receiving cash.",
    },
    {
      label: "Import Stock",
      image: require("@/assets/menu-icons/discount.png").default,
      to: "/store/import-stock",
      description:
        "Add and Manage corporate heads .You can create new corporates and there details to provide your services.",
    },
   
    
    {
      label: "Stocks",
      image: require("@/assets/menu-icons/schedule.png").default,
      to: "/store/stocks",
      description:
        "Create Schedule for corporates and regular customers .You can manage schedules and assign schedules of stores or delete the assigned schedules.",
    },
    {
      label: "Laboratory Settings",
      image: require("@/assets/menu-icons/schedule.png").default,
      to: "/store/lab-settings",
      description:
        "Create Schedule for corporates and regular customers .You can manage schedules and assign schedules of stores or delete the assigned schedules.",
    },
    {
      label: "Profilers",
      image: require("@/assets/menu-icons/customers.png").default,
      to: "/store/profilers",
      description:
        "Search customers or create new or Manage existing one for all stitch it customers.You can set customers measurements and view there transaction history.",
    },
    {
      label: "Printer Setup",
      image: require("@/assets/menu-icons/printer.jpg").default,
      to: "/store/printer-setup",
      description:
        "Create new printer and attach it to the application. you can set two types of printer from regular billing purpose and for dryclean purpose.",
    },
    {
      label: "Receipt Content",
      image: require("@/assets/menu-icons/printer_receipt.jpg").default,
      to: "/store/receipt-printer",
      description:
        "Add extra content under the store receipt . You can add or delete content for the store receipt from here.",
    },
    {
      label: "Requested Items",
      image: require("@/assets/menu-icons/polling.png").default,
      to: "/store/requested-items",
      description:
        "Search, Create and Manage store wise.You can add or delete the survey.",
    },
    {
      label: "Privileges",
      image: require("@/assets/menu-icons/polling.png").default,
      to: "/store/privileges",
      description:
        "Search, Create and Manage store wise.You can add or delete the survey.",
    },
  ];
}
