
import { Options, Vue } from "vue-class-component";
import PrivilegesService from "../../service/PrivilegesService.js";
import Toaster from "../../helpers/Toaster";

interface IRoles
{
  id:number,
  name:string
}

@Options({
  components: {},
})

export default class Privileges extends Vue {
  private selectedRoleID = 0;
  private toast;
  private privilegesService;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Privileges" },
  ];

  private roles: IRoles [] = [];
  private permission: IRoles [] = [];
  private checkedPermission = [];

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.privilegesService = new PrivilegesService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.privilegesService
      .getItems(this.selectedRoleID)
      .then((data) => {
        this.roles = data.roles;
        this.permission = data.permission;
        this.checkedPermission = data.assignedPermission;
      });
  }

  savePermissions()
  {
    this.privilegesService
      .saveItem(this.selectedRoleID,this.checkedPermission)
    .then((res) => {
      this.toast.handleResponse(res);
    });
  }
}
