
import { Options, Vue } from "vue-class-component";
import BankService from "../../service/BankService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";

@Options({
  components: {},
})
export default class Bank extends Vue {
  private lists = [];
  private dialogTitle;
  private toast;
  private goToFirstLink = 0;
  private currentStoreID = 0;
  private bankService;
  private productDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Banks" },
  ];

  private columns = [
    { field: "bank", header: "Bank Name" },
    { field: "branch", header: "Branch Name" },
    { field: "code", header: "Branch Code" },
    { field: "title", header: "Account Title" },
    { field: "number", header: "Account Number" },
    { field: "type", header: "Account Type" },
    { field: "ending_date", header: "Statement Date" },
    { field: "balance", header: "Start/Prev Balance" },
  ];

  private selectedColumns = this.columns;

  private item = {
    id: 0,
    endingDate: moment().toString(),
    balance: 0,
    status: "Active",
  };

  private state = reactive({
    bankName: "",
    branchName: "",
    code: "",
    title: "",
    accountNumber: "",
    type: { key: "New Account" },
  });

  private accountTypes = [{ key: "New Account" }, { key: "Existing Account" }];

  private validationRules = {
    bankName: {
      required,
    },
    branchName: {
      required,
    },
    code: {
      required,
    },
    title: {
      required,
    },
    accountNumber: {
      required,
    },
    type: {
      required,
    },
  };

  private v$ = useVuelidate(this.validationRules, this.state);

  private storeList = [];
  private selectedStore = {
    id: 0,
  };

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.bankService = new BankService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItems();
    this.submitted = false;
    this.dialogTitle = "Add New Bank";
    this.productDialog = true;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(isFormValid) {
    this.submitted = true;
    if (isFormValid) {
      if (this.item.id != 0) {
        this.item.endingDate = moment(this.item.endingDate).format(
          "YYYY-MM-DD"
        );
        this.bankService.updateItem(this.item, this.state).then((res) => {
          this.loadList(this.goToFirstLink);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.item.endingDate = moment(this.item.endingDate).format(
          "YYYY-MM-DD"
        );
        this.bankService
          .saveItem(this.item, this.state, this.currentStoreID)
          .then((res) => {
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
          });
      }

      this.productDialog = false;
      this.clearItems();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Bank";
    this.productDialog = true;

    this.bankService.getItem(data).then((res) => {
      if (res.length > 0) {
        this.state.bankName = res[0].bank;
        this.state.branchName = res[0].branch;
        this.state.code = res[0].code;
        this.state.title = res[0].title;
        this.state.accountNumber = res[0].number;
        this.state.type.key = res[0].type;
        this.item.id = res[0].id;
        this.item.endingDate = res[0].ending_date;
        this.item.balance = Number(res[0].balance);
        this.item.status = res[0].status;
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data) {
    this.item.id = data.id;
    this.state.bankName = data.bank;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.item.status = "Delete";
    this.bankService.changeStatus(this.item).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.bankService.getItems(this.selectedStore.id, page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
      this.storeList = data.stores;
      this.currentStoreID = data.currentStoreID;
    });
  }

  clearItems() {
    this.item = {
      id: 0,
      endingDate: moment().format("DD-MM-YYYY").toString(),
      balance: 0,
      status: "Active",
    };

    this.state.bankName = "";
    this.state.branchName = "";
    this.state.code = "";
    this.state.title = "";
    this.state.accountNumber = "";
    this.state.type = { key: "New Account" };
  }

  resetBalance() {
    if (this.state.type.key == "New Account") {
      this.item.balance = 0;
    }
  }
}
