
import { Options, Vue } from "vue-class-component";
import PosService from "../../service/PosService.js";
import ProfilerService from "../../service/ProfilerService.js";
import ChartService from "../../service/ChartService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import SearchFilter from "../../components/SearchFilter.vue";
import PosPreviewReceipt from "../../components/PosPreviewReceipt.vue";
import PaymentScreen from "../../components/PaymentScreen.vue";
import { useStore, ActionTypes } from "../../store";

@Options({
  components: {
    PaymentScreen,
    AutoComplete,
    SearchFilter,
    PosPreviewReceipt,
  },
})
export default class TransactionReceipt extends Vue {
  private lists = [];

  private selectedReceiptData = {
    paymentTransactions: []
  };
  
  private profilerList = [];
  private accountHeadList = [];
  private store = useStore();
  private totalAmountStatement = "Total Amount Received";
  private statement = "";
  private menuBar = false;
  private paymentDialog = false;
  private dialogTitle = "";
  private toast;
  private goToFirstLink = 0;
  private receiptNO = "";
  private previewHeading = "";
  private receiptID = 0;
  private receiptService;
  private profilerService;
  private chartService;
  private previewTransactionDialog = false;
  private previewPosReceipt = false;
  private productDialog = false;
  private filterDialog = false;
  private submitted = false;
  private statusDialog = false;
  private checkPagination = true;
  private totalRecords = 0;
  private totalBalanceReceipt = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Transactions" },
  ];

  private receiptTypes = [
    { key: "Sales Receipt", value: "INE" },
    { key: "Refund Receipt", value: "RFD" },
    { key: "Transfer Stocks", value: "TRN" },
    { key: "Arrived Stocks", value: "ASR" },
    { key: "Purchase Stocks", value: "PUR" },
    { key: "Purchase Return", value: "RPU" },
  ];

  private itemFilter = {
    keyword: "",
    filterType: "None",
    storeID: 0,
    date1: "",
    date2: "",
    type: "INE",
  };


  private item = {
    id: 0,
    paymentList: [],
    profileID: 0,
    selectedProfile: "",
    type: "",
    status: "",
  };

  

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.receiptService = new PosService();
    this.profilerService = new ProfilerService();
    this.chartService = new ChartService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }


  openFilterDialog() {
    this.dialogTitle = "Filter Receipt";
    this.filterDialog = true;
  }


  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.receiptService.transactionList(this.itemFilter, page).then((data) => {
      this.lists = data.records;
      this.totalRecords = data.totalRecords;
      this.limit = data.limit;
      this.statement = data.statement;
    });
  }


  loadSearchData() {
    this.submitted = true;
    if (this.itemFilter.keyword) {
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
  }

  fixDigits(amt) {
    let total = 0;

    if (amt != null) {
      total = amt.toFixed(2);
    }
    return total;
  }

  formatDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  formatTime(time) {
    return moment(time).format("hh:mm A");
  }
  
  formatDateTime(date) {
    return moment(date).format("DD-MM-YYYY hh:mm A");
  }

  updateFilterStatus(obj) {
    if (obj != null && obj.loading == "Yes") {
      this.itemFilter.filterType = obj.filterName.value;
      this.itemFilter.storeID = obj.storeID.id;
      this.itemFilter.date1 = obj.date1;
      this.itemFilter.date2 = obj.date2;
      this.itemFilter.keyword = "";
      this.goToFirstLink = 0;
      this.loadList(this.goToFirstLink);
    }
    this.filterDialog = false;
  }

  calculateBalance(totalBill, paymentList,) {
    let totalAmount = 0;

    paymentList.forEach((e) => {
      totalAmount = totalAmount + Number(e.trans_total_amount);
    });

    return Number((totalBill - totalAmount).toFixed(2));
  }


  openTransactionDialog() {
    this.previewTransactionDialog = true;
    this.menuBar = false;
  }
  
  openPaymentScreen() {
    this.paymentDialog = true;
    this.menuBar = false;
    this.dialogTitle = "Recieve Or Pay Receipt Dues"

    if (Number(this.totalBalanceReceipt) > 0) {
      this.paymentDialog = true;
      this.store.dispatch(
        ActionTypes.TOTAL_BILL,
        Number(this.fixDigits(this.totalBalanceReceipt))
      );
    }
    else
    {
      this.toast.showError('Receipt balance is not valid to proceed next');
    }
  }

  openMenu(data)
  {
    this.menuBar = true
    this.selectedReceiptData.paymentTransactions = data.payment_transactions;
    this.item.type = data.type;
    this.item.profileID = data.profile_id;
    this.item.id = data.id;
    this.item.status = data.status;
    this.item.selectedProfile = data.profile_name.profileName;

    this.totalBalanceReceipt =  this.calculateBalance(data.total_bill,data.payment_transactions);
    console.log(this.selectedReceiptData);
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList) {
    this.item.paymentList = paymentList;
   
    console.log(this.item);


    this.receiptService
      .savePayment(this.item)
      .then((res) => {
        if (res.alert == "info") {
          this.loadList(this.goToFirstLink);
          this.clearAll();
        }

        this.toast.handleResponse(res);
      });

    this.paymentDialog = false;
    this.submitted = false;
  }

  stockLeft()
  {
    this.receiptService
      .stockLeft(this.item)
      .then((res) => {
        if (res.alert == "info") {
          this.loadList(this.goToFirstLink);
          this.clearAll();
        }

        this.toast.handleResponse(res);
    });

    this.menuBar = false;
  }
  
  saveStock()
  {
    this.receiptService
      .saveStock(this.item)
      .then((res) => {
        if (res.alert == "info") {
          this.loadList(this.goToFirstLink);
          this.clearAll();
        }

        this.toast.handleResponse(res);
    });

    this.menuBar = false;
  }

  getPaymentMwthod(paymnetList) {
    let method = "";

    if (paymnetList.length == 0) {
      method = "Pay Later";
    } else if (paymnetList.length == 1) {
      method = paymnetList[0].paymentType;
    } else if (paymnetList.length > 1) {
      method = "Split";
    }

    return method;
  }
  voidReceipt()
  {
    this.receiptService
      .voidStock(this.item)
      .then((res) => {
        if (res.alert == "info") {
          this.loadList(this.goToFirstLink);
          this.clearAll();
        }

        this.toast.handleResponse(res);
    });

    this.menuBar = false;
  }

  clearAll()
  {

    this.item = {
      id: 0,
      paymentList: [],
      profileID: 0,
      selectedProfile: "",
      type: "",
      status: "",
    };
  }

  updatePreviewStatus() {
    this.previewPosReceipt = false;
  }
  
  previewDialog() {
    this.receiptID = this.item.id;
    this.previewPosReceipt = true;
    this.menuBar = false;
  }
}
