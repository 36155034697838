
import { Options, Vue } from "vue-class-component";
import StockService from "../../service/StockService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";

import FileUploader from "../../components/FileUploader.vue";


@Options({
  components: {
    AutoComplete,
    FileUploader
  },
})
export default class ImportStock extends Vue {

  private taxName1 = '';
  private taxName2 = '';
  private taxName3 = '';

  private totalInValid = 0;
  private totalValid = 0;


  private productType = [
    {
      id : 0,
      option_name : '',
    }
  ];
  private brand  = [
    {
      id : 0,
      option_name : '',
    }
  ];     
  private brandSector = [
    {
      id : 0,
      option_name : '',
    }
  ];
  private category   = [
    {
      id : 0,
      option_name : '',
    }
  ]; 

  private toast;
  private stockService;
  private uploaderStatus = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Import Stock" },
  ];


  private excelFileContent = [
    {
      'productName' : '',
			'genericName' : '',
			'barcode' : '',
			'productType' : 0,
			'brandName' : 0,
			'brandSector' : 0,
			'category' : 0,
			'sideEffects' : '',
			'stripSize' : 0,
			'packSize' : 0,
			'quantity' : 0,
			'expiryDate' : '',
			'packPurchasePrice' : 0,
			'packSellingPrice' : 0,
			'mRP' : 0,
			'batchNo' : '',
			'tax_1' : 0,
			'tax_2': 0 ,
			'tax_3' : 0,
			'discountPercentage' : 0,
			'description' : '',
			'minimumStock' : 0,
			'storeLocations' : '',
    }
  ];


  //DEFAULT METHOD OF TYPE SCRIPT
  created() {
    this.stockService = new StockService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  downloadSample() {
    this.stockService.exportSampleStock().then((res) => {
      let fileURL = window.URL.createObjectURL(new Blob([res]));
      let fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download','sampleData.csv');
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.stockService.getItems().then((data) => {
      this.productType  = data.productType;
      this.brand        = data.brand;
      this.brandSector  = data.brandSector;
      this.category     = data.category;

      this.taxName1 = (data.storeTaxes[0].show_1 == 'true' ? data.storeTaxes[0].tax_name_1 : '');
      this.taxName2 = (data.storeTaxes[0].show_2 == 'true' ? data.storeTaxes[0].tax_name_2 : '');
      this.taxName3 = (data.storeTaxes[0].show_3 == 'true' ? data.storeTaxes[0].tax_name_3 : '');
    });
  }



  openFileUploader()
  {
    this.uploaderStatus = true;
  }


  updateUploaderStatus(params)
  {
    this.uploaderStatus = false;
    if(params.length > 0)
    {
      this.excelFileContent = [];

      this.stockService.uploadCSVFile(params[0]).then((res) => {
          if(res.length > 0)
          {
            res.forEach(e => {
              if( e[0] != 'Product Name (*) [Text]')
              {

                let categoryID = 0;
                let bandID = 0;
                let bandSectorID = 0;
                let productTypeID = 0;

                this.productType.forEach(i => {
                  if(i.option_name == e[3])
                  {
                    productTypeID =  i.id;
                  }
                });

                this.brand.forEach(i => {
                  if(i.option_name == e[4])
                  {
                    bandID =  i.id;
                  }
                });

                this.brandSector.forEach(i => {
                  if(i.option_name == e[5])
                  {
                    bandSectorID =  i.id;
                  }
                });
                
                this.category.forEach(i => {
                  if(i.option_name == e[6])
                  {
                    categoryID =  i.id;
                  }
                });

                this.excelFileContent.push(
                  {
                    'productName' :         e[0],
                    'genericName' :         e[1],
                    'barcode' :             e[2],
                    'productType' :         productTypeID,
                    'brandName' :           bandID,
                    'brandSector' :         bandSectorID,
                    'category' :            categoryID,
                    'sideEffects' :         e[7],
                    'stripSize' :           Number(e[8]),
                    'packSize' :            Number(e[9]),
                    'quantity' :            Number(e[10]),
                    'expiryDate' :          e[11],
                    'packPurchasePrice' :   Number(e[12]),
                    'packSellingPrice' :    0,
                    'mRP' :                 Number(e[13]),
                    'batchNo' :             e[14],
                    'tax_1' :               Number(e[15]),
                    'tax_2':                Number(e[16]),
                    'tax_3' :               Number(e[17]),
                    'discountPercentage' :  Number(e[18]),
                    'description' :         e[19],
                    'minimumStock' :        Number(e[20]),
                    'storeLocations' :      e[21],
                  }
                );
              }
            });
          }
      });
    }
  }

  saveFileData()
  {
    if (this.validateStock.length == 0) {
      this.stockService.save(this.excelFileContent).then((res) => {
        this.clearAll();
        this.toast.handleResponse(res);
      });
    }
    else
    {
      this.toast.showWarning('Some of the fields are invalid'); 
    }
  }

  getPackSellingPrice(data)
  {
    const tax_1 = Number(data.tax_1);
    const tax_2 = Number(data.tax_2);
    const tax_3 = Number(data.tax_3);
    const mrp   = Number(data.mRP);

    const totalTax = tax_1 + tax_2 + tax_3;
    const avgTax = 100 + totalTax;
		const tax = (mrp / avgTax) * totalTax;
		const packPrice = (mrp - tax).toFixed(2);

    data.packSellingPrice = Number(packPrice);

    return Number(packPrice);
  }


  clearAll() {
    this.excelFileContent = [];
    this.totalInValid = 0;
    this.totalValid = 0;
    this.addNewRow();
    this.toast.showSuccess("Cleared Successfully");
  }

  addNewRow()
  {
    this.excelFileContent.push({
      'productName' : '',
			'genericName' : '',
			'barcode' : '',
			'productType' : 0,
			'brandName' : 0,
			'brandSector' : 0,
			'category' : 0,
			'sideEffects' : '',
			'stripSize' : 0,
			'packSize' : 0,
			'quantity' : 0,
			'expiryDate' : '',
			'packPurchasePrice' : 0,
			'packSellingPrice' : 0,
			'mRP' : 0,
			'batchNo' : '',
			'tax_1' : 0,
			'tax_2': 0 ,
			'tax_3' : 0,
			'discountPercentage' : 0,
			'description' : '',
			'minimumStock' : 0,
			'storeLocations' : '',
    });
  }

  clearListItem(item) {
    this.excelFileContent.splice(this.excelFileContent.indexOf(item), 1);
    this.toast.showSuccess("Row Deleted Successfully");
  }

  get stockWorth()
  {
    let totalAmount = 0;
    this.excelFileContent.forEach(e => {
        if(e.packSize != 0)
        {
          totalAmount = totalAmount + Number((e.quantity/e.packSize) * e.packPurchasePrice);
        }
    });
    return totalAmount.toFixed(2);
  }
  
  getPackWorth(data)
  {
    let totalAmount = 0;
    this.excelFileContent.forEach(e => {
      if(data.packSize != 0)
      {
        totalAmount =  Number((data.quantity/data.packSize) * data.packPurchasePrice);
      }
    });
    return totalAmount.toFixed(2);
  }

  get totalItems()
  {
    return  this.excelFileContent.length;
  }

  get validateStock() {
    this.totalInValid = 0;
    this.totalValid = 0;
    let invalidListItems: Number[] = [];
    this.excelFileContent.map((v, index) => {
      if (
        v.productName == null || v.productName == "" || v.genericName == null || v.genericName == "" ||
        v.expiryDate == null || v.expiryDate == "" ||
        v.batchNo == null || v.batchNo == "" ||
        v.productType == 0 || v.brandName == 0 ||  v.brandSector == 0 ||
        v.category == 0 || v.packSize <= 0 || v.quantity <= 0 || v.packPurchasePrice <= 0 ||
        v.mRP <= 0
        ) {
        this.totalInValid++;
        invalidListItems.push(index);
      }
      else
      {
         this.totalValid++;
      }
    });
    return invalidListItems;
  }
}
