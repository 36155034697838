<template>
    <div class="p-grid">
        <h6 class="heading-style p-col-12 p-m-0">
            <i class="pi pi-check-circle"></i> Patient Diagnosis <br />
            <small
                >Measure patients diagnosis details and enter inside these
                fields</small
            >
        </h6>
        <div class="p-col-8 p-m-0 p-mt-2">
            <div class="p-grid">
               <div class="p-col-12">
                    <div class="p-field">
                        <AutoComplete
                            :delay="1000"
                            :minLength="3"
                            @item-select="saveDiagnosis($event)"
                            scrollHeight="500px"
                            v-model="itemScanBox"
                            :suggestions="itemList"
                            placeholder=" Search Diagnosis"
                            @complete="searchDiagnosis($event)"
                            :dropdown="false"
                            autoFocus
                        >
                            <template #item="slotProps">
                                <div>
                                    <span class="p-mr-1">
                                        CODE :
                                        <b class="pull-right">
                                            {{
                                                slotProps.item.option_name.toUpperCase()
                                            }}
                                        </b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <b class="pull-right">
                                            {{
                                                slotProps.item.description.toUpperCase()
                                            }}
                                        </b>
                                    </span>
                                </div>
                            </template>
                        </AutoComplete>
                    </div>
                </div>
                <template v-for="d in diagnosisList" :key="d">
                    <div class="p-col-12 p-p-0">
                        <p class="diagnosis-style">
                            <b> <i style="color:#c00;" class="pi pi-arrow-circle-right"></i> CODE {{d.code}} </b> | {{d.diagnosis}}
                        </p>
                    </div>
                    <div class="p-col-2 p-p-0">
                        <div class="p-field">
                            <ToggleButton v-model="d.response" onLabel="CONFIRMED" offLabel="ASSUMED" onIcon="pi pi-check" offIcon="pi pi-question-circle" class="p-button-sm"  />
                        </div>
                    </div>
                </template>

                <div class="p-col-12 p-text-right">
                    <Button
                        type="button"
                        style="width: 15rem"
                        label="Save"
                        icon="pi pi-check-circle"
                        class="p-button-primary p-button-lg"
                        :disabled="diagnosisList.length == 0"
                        @click.prevent="saveItem()"
                    />
                </div>
            </div>
        </div>
        <div class="p-col-4 p-mt-1 sec-2-height">
            <template v-for="(item, index) in sortedRecords" :key="index">
                <Panel
                    :header="index"
                    :toggleable="true"
                    class="p-p-0"
                    :collapsed="getTodaysDate!= index"
                >
                    <template v-for="(i, k) in item" :key="k">
                        <table class="table table-striped">
                            <tr class="list-row-style">
                                <td class="p-p-1">SNO : {{ k + 1 }}</td>
                                <td class="p-p-1 p-text-right">
                                    <Button
                                        type="button"
                                        icon="pi pi-times"
                                        class="p-button-danger p-p-1"
                                        @click.prevent="deleteItem(i.id)"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="p-text-left p-p-1">
                                    {{ i.diagnosis }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Code  {{ i.code }}</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.feedback == 0 ? 'ASSUMED' : 'CONFIRM'  }}
                                </td>
                            </tr>
                        </table>
                    </template>
                </Panel>
            </template>
        </div>
    </div>

    <component
        v-if="componentName != ''"
        v-bind:is="componentName"
        v-on:updateConfirmationStatus="updateConfirmation"
    >
    </component>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import DiagnosisService from "../service/DiagnosisService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";
import AutoComplete from "primevue/autocomplete";
import ToggleButton from 'primevue/togglebutton';

interface recordTypes {
    pDate: Date;
}

interface diagnosisTypes {
    code: String;
    diagnosis: String;
    response: Boolean;
}

@Options({
    components: {
        Confirmation,
        AutoComplete,
        ToggleButton
    }
})

export default class Diagnosis extends Vue {
    private recordList: recordTypes[] = [];
    private diagnosisList: diagnosisTypes [] = [];
    private recordID = 0;
    private pService;
    private itemList = [];
    private toast;
    private componentName = "";
    private vuexStore = useStore();
    private itemScanBox = "";

    
    created() {
        this.pService = new DiagnosisService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    saveItem() {
      
        this.pService
            .save(this.diagnosisList, this.patientID)
            .then(res => {
                this.clearItems();
                this.toast.handleResponse(res);
                this.loadList();
            });
        
    }

    clearItems() {
      this.diagnosisList = [];
    }

    loadList() {
        this.pService.get(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

     get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach(e => {
            
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

           
            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.delete(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }

    searchDiagnosis(event) {
        setTimeout(() => {
            this.pService.searchItem(event.query.trim()).then(data => {
                this.itemList = data.records;
            });
        }, 200);
    }

    saveDiagnosis(event) {
        const itemInfo = event.value;
        this.itemScanBox = '';

        this.diagnosisList.push({
            code: itemInfo.option_name,
            diagnosis:itemInfo.description,
            response: false
        });
    }
}
</script>

<style scoped>
.diagnosis-style
{
    background-color:#eee;
}
.heading-style {
    background-color: green;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
    border-radius: 2rem;
}

.sec-2-height {
    height: 75vh;
    min-height: 75vh;
    overflow-y: scroll;
    background-color: #f7f7f7;
    border-left: 1px solid #ccc;
}

.list-row-style {
    background-color: #818181;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
}
</style>
