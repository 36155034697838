
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import ReceiptService from "../service/ReceiptService.js";

@Options({
  props: {
    PreviewAccountingReceipt: Object,
  },
  watch: {
    PreviewAccountingReceipt(obj) {
      this.openDialog();
      this.dialogTitle = obj.dialogTitle;
      this.productDialog = obj.status;
      this.previewHeading = obj.previewHeading;

      if (obj.receiptID != 0) {
        this.loadReceipt(obj.receiptID);
      }
    },
  },
  emits: ["updatePreviewStatus"],
})
export default class PreviewAccountingReceipt extends Vue {
  private toast;
  private totalBalance = 0;
  private PaymentLists = [
    {
      createdDate: "",
      updatedDate: "",
      receiptNo: "",
      receiptDate: "",
      totalAmount: 0,
      description: "",
      transactionType: "",
      type: "",
    },
  ];
  private productDialog = false;
  private dialogTitle = "";
  private receiptService;
  private previewHeading = "";
  private item = {
    storeName: "",
    storeAddress: "",
    storeEmail: "",
    storePhone: "",
    storeLicense: "",
    receiptNO: "",
    type: "",
    description: "",
    receiptDate: "",
    receiptDueDate: "",
    selectedProfile: "",
    totalTax1: 0,
    totalTax2: 0,
    totalTax3: 0,
    totalGross: 0,
    totalDiscount: 0,
    totalTax: 0,
    totalBill: 0,
    itemList: [
      {
        accountID: 0,
        accountHead: "",
        quantity: 1,
        price: 0,
        discount: 0,
        subTotal: 0,
        tax1Value: 0,
        tax2Value: 0,
        tax3Value: 0,
      },
    ],
  };

  private taxNames = [
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
  ];

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.toast = new Toaster();
    this.receiptService = new ReceiptService();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.productDialog = true;
  }

  closeDialog() {
    this.$emit("updatePreviewStatus", {});
    this.productDialog = false;
  }

  getCompanyURL() {
    return require("@/assets/images/logo.png").default;
  }

  loadReceipt(receiptID) {
    this.receiptService.getReceiptData(receiptID).then((res) => {
      if (res != null) {
        this.item.storeName = res.storeDetail.name;
        this.item.storeAddress = res.storeDetail.address;
        this.item.storeEmail = res.storeDetail.email;
        this.item.storePhone = res.storeDetail.contact;
        this.item.storeLicense = res.storeDetail.license_no;
        this.item.receiptNO = res.receipt.receipt_no;
        this.item.description = res.receipt.description;
        this.item.receiptDate = res.receipt.receipt_date;
        this.item.receiptDueDate = res.receipt.receipt_due_date;
        this.item.type = res.receipt.type;
        this.item.selectedProfile = res.receipt.profile_name.profileName;
        this.item.totalTax1 = Number(res.receipt.total_tax1);
        this.item.totalTax2 = Number(res.receipt.total_tax2);
        this.item.totalTax3 = Number(res.receipt.total_tax3);
        this.item.totalGross = Number(res.receipt.gross_total);
        this.item.totalDiscount = Number(res.receipt.total_discount);
        this.item.totalTax = Number(res.receipt.total_tax);
        this.item.totalBill = Number(res.receipt.total_bill);

        this.receiptPayments(res.receipt);
        //CALCULATE TOTAL BALANCE
        this.totalBalance = this.calculateBalance(
          res.receipt.total_bill,
          res.receipt.cash_transactions,
          res.receipt.bank_transactions
        );

        let vList = res.subReceipt;

        if (vList.length > 0) {
          this.item.itemList = [];

          vList.map((v) => {
            this.item.itemList.push({
              accountID: Number(v.sub_transaction_id),
              accountHead: v.chart_name.chartName,
              quantity: Number(v.qty),
              price: Number(v.price),
              discount: Number(v.discount),
              subTotal: Number(v.sub_total),
              tax1Value: Number(v.tax1),
              tax2Value: Number(v.tax2),
              tax3Value: Number(v.tax3),
            });
          });
        }

        //taxNames
        this.taxNames = [];

        this.taxNames.push({
          taxName: res.storeDetail.tax_name_1,
          show: res.storeDetail.show_1,
          optionalReq: res.storeDetail.required_optional_1,
          taxValue:
            res.storeDetail.show_1 == "true"
              ? Number(res.storeDetail.tax_value_1)
              : 0,
          accountHead: res.storeDetail.tax_name1.chartName,
          accountID: res.storeDetail.link1,
        });

        this.taxNames.push({
          taxName: res.storeDetail.tax_name_2,
          show: res.storeDetail.show_2,
          optionalReq: res.storeDetail.required_optional_2,
          taxValue:
            res.storeDetail.show_2 == "true"
              ? Number(res.storeDetail.tax_value_2)
              : 0,
          accountHead: res.storeDetail.tax_name2.chartName,
          accountID: res.storeDetail.link2,
        });

        this.taxNames.push({
          taxName: res.storeDetail.tax_name_3,
          show: res.storeDetail.show_3,
          optionalReq: res.storeDetail.required_optional_3,
          taxValue:
            res.storeDetail.show_3 == "true"
              ? Number(res.storeDetail.tax_value_3)
              : 0,
          accountHead: res.storeDetail.tax_name3.chartName,
          accountID: res.storeDetail.link3,
        });
      }
    });
  }

  fixDigits(amt) {
    let total = 0;

    if (amt != null) {
      total = amt.toFixed(2);
    }
    return total;
  }

  formatDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  formatDateTime(date) {
    return moment(date).format("DD-MM-YYYY hh:mm A");
  }

  printReceipt() {
    window.print();
  }

  calculateBalance(totalBill, cashTransactions, bankTransactions) {
    let totalAmount = 0;

    cashTransactions.forEach((e) => {
      totalAmount = totalAmount + Number(e.total_amount);
    });

    bankTransactions.forEach((e) => {
      totalAmount = totalAmount + Number(e.amount);
    });

    return Number((totalBill - totalAmount).toFixed(2));
  }

  receiptPayments(data) {
    this.PaymentLists = [];

    data.cash_transactions.forEach((e) => {
      this.PaymentLists.push({
        createdDate: e.created_at,
        updatedDate: e.updated_at,
        receiptNo: e.voucher_no,
        receiptDate: e.voucher_date,
        totalAmount: e.total_amount,
        description: e.memo,
        transactionType: e.account_type,
        type: e.type,
      });
    });

    data.bank_transactions.forEach((e) => {
      this.PaymentLists.push({
        createdDate: e.created_at,
        updatedDate: e.updated_at,
        receiptNo: e.receipt_no,
        receiptDate: e.receipt_date,
        totalAmount: e.amount,
        description: e.description,
        transactionType: e.transaction_no,
        type: e.type,
      });
    });
  }
}
