<template>
    <div class="p-grid">
        <h6 class="heading-style p-col-12 p-m-0">
            <i class="pi pi-check-circle"></i> Patient Medications<br />
            <small>Measure patients medications details and enter inside these fields</small>
        </h6>
        <div class="p-col-6 p-m-0 p-mt-2">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-field">
                        <label for="drugName">Drug Name</label>
                        <AutoComplete
                            :delay="1000"
                            :minLength="3"
                            @item-select="saveMedicine($event)"
                            scrollHeight="500px"
                            v-model="itemScanBox"
                            :suggestions="itemList"
                            placeholder=" Search Medicine"
                            @complete="searchMedicine($event)"
                            :dropdown="false"
                            autoFocus
                        >
                            <template #item="slotProps">
                                <div>
                                    <span class="p-mr-1">
                                        NAME :
                                        <b class="pull-right">
                                            {{
                                                slotProps.item.product_name.toUpperCase()
                                            }}
                                        </b>
                                    </span>
                                    <span class="p-mx-1">
                                        EXPIRY DATE :
                                        <b class="pull-right">
                                            {{
                                                formatExpiryDate(
                                                    slotProps.item.expiry_date
                                                )
                                            }}
                                        </b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        GENERIC :
                                        <b class="pull-right">
                                            {{
                                                slotProps.item.generic.toUpperCase()
                                            }}
                                        </b>
                                    </span>
                                </div>
                                <div>
                                    <small>
                                        BATCH NO :
                                        <b class="pull-right">
                                            {{ slotProps.item.batch_no }}
                                        </b>
                                    </small>
                                    <small>
                                        Total Units :
                                        <b class="pull-right">
                                            {{ slotProps.item.qty }}
                                        </b>
                                    </small>
                                    <small>
                                        Brand Name :
                                        <b class="pull-right">
                                            {{ slotProps.item.bName }}
                                        </b>
                                    </small>
                                </div>
                            </template>
                        </AutoComplete>
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label for="dose">Dose</label>
                        <InputNumber
                            :useGrouping="false"
                            id="dose"
                            v-model="item.dose"
                            showButtons
                            buttonLayout="horizontal"
                            incrementButtonClass="p-button-primary"
                            decrementButtonClass="p-button-warning"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            :min="1"
                        />
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label for="Unit">Unit</label>
                        <Dropdown
                            id="Unit"
                            v-model="item.unit"
                            :options="unitLists"
                            optionLabel="name"
                            optionValue="name"
                        />
                    </div>
                </div>

                <div class="p-col-6">
                    <div class="p-field">
                        <label for="Unit">Frequency</label>
                        <Dropdown
                            id="Unit"
                            v-model="item.frequency"
                            :options="frequencyLists"
                            optionLabel="name"
                            optionValue="name"
                        />
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label for="Duration">Duration</label>
                        <InputNumber
                            :useGrouping="false"
                            id="Duration"
                            v-model="item.duration"
                            showButtons
                            buttonLayout="horizontal"
                            incrementButtonClass="p-button-primary"
                            decrementButtonClass="p-button-warning"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            :min="1"
                        />
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label for="Unit">Unit</label>
                        <Dropdown
                            id="Unit"
                            v-model="item.days"
                            :options="dayLists"
                            optionLabel="name"
                            optionValue="name"
                        />
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label for="instruction">Additional Instruction</label>
                        <InputText
                            id="instruction"
                            v-model="item.instruction"
                        />
                    </div>
                </div>

                <div class="p-col-12 p-text-center">
                    <Button
                        type="button"
                        style="width: 15rem"
                        label="Save"
                        icon="pi pi-check-circle"
                        class="p-button-primary p-button-lg"
                        @click.prevent="saveItem()"
                        :disabled="item.medicineID == 0 || itemScanBox == ''"
                    />
                </div>
            </div>
        </div>
        <div class="p-col-6 p-mt-1 sec-2-height">
            <template v-for="(item, index) in sortedRecords" :key="index">
                <Panel
                    :header="index"
                    :toggleable="true"
                    class="p-p-0"
                    :collapsed="getTodaysDate!= index"
                >
                    <template v-for="(i, k) in item" :key="k">
                        <table class="table table-striped">
                            <tr class="list-row-style">
                                <td class="p-p-1">SNO : {{ k + 1 }}</td>
                                <td class="p-p-1 p-text-right">
                                    <Button
                                        type="button"
                                        icon="pi pi-times"
                                        class="p-button-danger p-p-1"
                                        @click.prevent="deleteItem(i.id)"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Drug Name</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.drugs.productName }} (
                                    {{ i.drugs.generic }} )
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Dose</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.dose }} {{ i.doseUnit }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Frequency</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.frequency }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Duration</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.duration }} {{ i.durationUnit }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Additional Instructions</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.additionalInstructions }}
                                </td>
                            </tr>
                        </table>
                    </template>
                </Panel>
            </template>
        </div>
    </div>

    <component
        v-if="componentName != ''"
        v-bind:is="componentName"
        v-on:updateConfirmationStatus="updateConfirmation"
    >
    </component>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import MedicationService from "../service/MedicationService";
import Confirmation from "../components/Confirmation.vue";
import { camelCase } from "lodash";
import moment from "moment";
import PosService from "../service/PosService.js";
import AutoComplete from "primevue/autocomplete";

interface recordTypes {
    pDate: Date;
}

@Options({
    components: {
        Confirmation,
        AutoComplete
    }
})
export default class Medications extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private posService;
    private pService;
    private toast;
    private componentName = "";
    private itemScanBox = "";
    private vuexStore = useStore();
    private itemList = [];
    private unitLists = [{ name: "Tablet" }, { name: "Syrup" }];
    private frequencyLists = [{ name: "Once a day" }, { name: "Twice a day" }];
    private dayLists = [{ name: "Day" }, { name: "Week" }, { name: "Month" }];
    private item = {
        medicineID: 0,
        unit: "Tablet",
        frequency: "Once a day",
        duration: 1,
        days: "Day",
        dose: 1,
        instruction: ""
    };

    created() {
        this.posService = new PosService();
        this.pService = new MedicationService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    saveItem() {
        this.pService.save(this.item, this.patientID).then(res => {
            this.clearItems();
            this.toast.handleResponse(res);
            this.loadList();
        });
    }

    clearItems() {
        this.item = {
            medicineID: 0,
            unit: "Tablet",
            frequency: "Once a day",
            duration: 1,
            days: "Day",
            dose: 1,
            instruction: ""
        };

        this.itemScanBox = "";
    }

    loadList() {
        this.pService.get(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

    get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach(e => {
            
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

           
            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.delete(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }

    searchMedicine(event) {
        setTimeout(() => {
            this.posService.searchItem(event.query.trim()).then(data => {
                this.itemList = data.records;
            });
        }, 200);
    }

    saveMedicine(event) {
        const itemInfo = event.value;
        this.itemScanBox = itemInfo.product_name;
        this.item.medicineID = itemInfo.id;
    }

    formatExpiryDate(d) {
        return moment(d).format("MMM-YYYY");
    }
}
</script>

<style scoped>
.heading-style {
    background-color: green;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
    border-radius: 2rem;
}

.sec-2-height {
    height: 75vh;
    min-height: 75vh;
    overflow-y: scroll;
    background-color: #f7f7f7;
    border-left: 1px solid #ccc;
}

.list-row-style {
    background-color: #818181;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
}
</style>
