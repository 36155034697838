
import { Vue, Options } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import PaymentService from "../service/PaymentService";
import { camelCase } from "lodash";
import { useStore } from "../store";

interface PaymentListType {
  paymentType: string;
  accountNo: string;
  terminalId: string;
  authCode: string;
  transId: string;
  transStatus: string;
  transType: string;
  transDate: string;
  transTime: string;
  transAmount: number;
  transTotalAmount: number;
  transRef: string;
  entryMode: string;
  hostResponse: string;
  giftCardRef: string;
  cardBalance: string;
  tendered: number;
  change: number;
  roundOff: number;
}

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.paymentDialog = obj.dialogStatus;
      this.closeConfirmation = obj.closeConfirmation;
      this.amountLeft = Number(this.totalBill);
      this.itemSource = obj.itemSource;
      this.restriction = obj.restriction;
      this.customerID = obj.customerID;
      this.customerName = obj.customerName;
      this.paymentAction.needlePoints = obj.needlePoints;
      this.dialogTilte = obj.dialogTilte + " for Customer " + this.customerName;
    },
  },
  emits: ["closePaymentScreenEvent", "getProceededPaymentsEvent"],
})
export default class PaymentScreen extends Vue {
  private customerID;
  private store = useStore();
  private customerName;
  private accountNo = "";
  private cardType = "";
  private paymentService;
  private paymentDialog = false;
  private paymentConfirmDialog = false;
  private closeConfirmation = false;
  private paymentCancelDialog = false;
  private itemSource = "";
  private transStatus = "000";
  private toast;
  private restriction = "";
  private screenNumber = "";
  private paymentMethodType = "Cash";
  private amountLeft = 0;
  private roundedAmt = 0;
  private tipAmountTerminal = 0;
  private paymentAction = {
    tendered: 0,
    needlePoints: 0,
  };

  private paymentList: PaymentListType[] = [];

  created() {
    this.paymentService = new PaymentService();
    this.toast = new Toaster();
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  get totalBill() {
    return this.store.getters.getTotalBill;
  }

  closePaymentScreen() {
    this.paymentList = [];
    this.$emit("closePaymentScreenEvent");
    this.paymentCancelDialog = false;
  }

  cancelPaymentConfirm() {
    this.paymentDialog = true;
    this.paymentCancelDialog = false;
  }

  confirmPaymentCancel() {
    if (this.totalPaymentsReceived > 0) {
      this.paymentCancelDialog = true;
    } else {
      this.paymentList = [];
      this.$emit("closePaymentScreenEvent");
      this.paymentDialog = false;
    }
  }

  amountNumpad(num) {
    num = String(num);

    if (num == "del") {
      this.paymentAction.tendered = 0;
      this.screenNumber = "";
    } else {
      if (this.paymentRounding > 0 || this.paymentMethodType == "Tip") {
        this.screenNumber = this.screenNumber + num;
        this.paymentAction.tendered = Number(this.screenNumber);
      } else {
        this.toast.showWarning("Invalid Amount must be greater then zero");
      }
    }
  }

  exactAmount() {
    this.paymentAction.tendered = this.paymentRounding;
  }

  fixLength(value) {
    const num = Number(value);
    value = num.toFixed(2);
    return value;
  }

  fixLengthNumber(value) {
    const num = Number(value);
    value = num.toFixed(2);
    value = Number(value);
    return value;
  }

  get totalPaymentsReceived() {
    let total = 0;

    this.paymentList.forEach((e) => {
      if (e.paymentType != "Tip") {
        total = total + e.transTotalAmount;
      }
    });

    return Number(total);
  }

  addCashAmount() {
    const tendered = Number(this.paymentAction.tendered);
    if (tendered == 0) {
      this.toast.showError("Please enter amount greater then zero");
    } else {
      if (!this.checkCashPayment) {
        const receivableAmount = this.fixLengthNumber(
          tendered - this.changeAmount
        );
        this.paymentList.push({
          paymentType: "Cash",
          accountNo: "",
          transTotalAmount: receivableAmount,
          terminalId: "Manual",
          authCode: "",
          hostResponse: "",
          transId: "",
          transStatus: this.transStatus,
          transType: this.itemSource,
          transDate: "",
          transTime: "",
          transAmount: receivableAmount,
          transRef: "",
          entryMode: "",
          giftCardRef: "",
          cardBalance: "",
          tendered: this.fixLengthNumber(tendered),
          change: this.fixLengthNumber(this.changeAmount),
          roundOff: this.fixLengthNumber(this.roundedAmt),
        });
      } else {
        this.toast.showError("Cash type is already added");
      }
    }
  }

  addManualAmount() {
    if (this.accountNo.length != 4 || this.cardType == "") {
      this.toast.showError("Please choose Card Type and Card No must be 4 digits");
    } else {
      const tendered = Number(this.paymentAction.tendered);
      if (tendered == 0) {
        this.toast.showError("Please enter amount greater then zero");
      } else {
        const receivableAmount = this.fixLengthNumber(
          tendered - this.changeAmount
        );

        this.paymentList.push({
          paymentType: this.cardType,
          accountNo: this.accountNo,
          transTotalAmount: receivableAmount,
          terminalId: "Manual",
          authCode: "",
          hostResponse: "",
          transId: "",
          transStatus: this.transStatus,
          transType: this.itemSource,
          transDate: "",
          transTime: "",
          transAmount: receivableAmount,
          transRef: "",
          entryMode: "",
          giftCardRef: "",
          cardBalance: "",
          tendered: this.fixLengthNumber(tendered),
          change: this.fixLengthNumber(this.changeAmount),
          roundOff: 0,
        });
        this.toast.showSuccess(this.cardType + " Payment added successfully");
        this.accountNo = "";
      }
    }
  }

  addNeedles() {
    const tendered = Number(this.paymentAction.tendered);
    const usedNeedle = this.sumNeedlesPayment();
    const withOutTen = Number(usedNeedle - tendered);

    if (tendered == 0) {
      this.toast.showError("Please enter amount greater then zero");
    } else if (
      usedNeedle > this.paymentAction.needlePoints &&
      (this.itemSource == "Checkout" || this.itemSource == "Checkin")
    ) {
      this.toast.showError(
        "Sorry Not enough needle points to be used. You have already used  amount of $" +
          withOutTen
      );
    } else {
      const receivableAmount = this.fixLengthNumber(
        tendered - this.changeAmount
      );

      this.paymentList.push({
        paymentType: "Needle",
        accountNo: "",
        transTotalAmount: receivableAmount,
        terminalId: "Manual",
        authCode: "",
        hostResponse: "",
        transId: "",
        transStatus: this.transStatus,
        transType: this.itemSource,
        transDate: "",
        transTime: "",
        transAmount: receivableAmount,
        transRef: "",
        entryMode: "",
        giftCardRef: "",
        cardBalance: "",
        tendered: this.fixLengthNumber(tendered),
        change: 0,
        roundOff: 0,
      });
      this.toast.showSuccess("Needles added successfully");
    }
  }

  addTip() {
    const tendered = Number(this.paymentAction.tendered);

    this.paymentList.push({
      paymentType: "Tip",
      accountNo: "",
      transTotalAmount: tendered,
      terminalId: "Manual",
      authCode: "",
      hostResponse: "",
      transId: "",
      transStatus: this.transStatus,
      transType: this.itemSource,
      transDate: "",
      transTime: "",
      transAmount: this.fixLengthNumber(tendered),
      transRef: "",
      entryMode: "",
      giftCardRef: "",
      cardBalance: "",
      tendered: 0,
      change: 0,
      roundOff: 0,
    });

    this.paymentAction.tendered = 0;
    this.toast.showSuccess("Tip added successfully");
  }

  get changeAmount() {
    let change = 0;
    const amountLeft = this.paymentRounding;
    const tendered = Number(this.paymentAction.tendered);
    const balance = tendered - amountLeft;

    if (balance > 0) {
      change = balance;
    }

    return change;
  }

  deletePayment(index, obj) {
    this.paymentList.splice(index, 1);
    this.toast.showSuccess(
      "Amount of $" +
        this.fixLength(obj.transTotalAmount) +
        " removed successfully"
    );
  }

  sumNeedlesPayment() {
    let total = 0;

    const tendered = Number(this.paymentAction.tendered);

    this.paymentList.forEach((e) => {
      if (e.paymentType == "Needle") {
        total = total + e.transTotalAmount;
      }
    });

    return total + tendered;
  }

  clearAccountNo() {
    this.accountNo = "";
  }

  get checkCashPayment() {
    let status = false;
    this.paymentList.forEach((e) => {
      if (e.paymentType == "Cash") {
        status = true;
      }
    });

    return status;
  }

  termianlPayment() {
    const tendered = this.fixLengthNumber(this.paymentAction.tendered);

    if (this.itemSource != "" && tendered > 0) {
      const transString = this.createTransactionMsg(
        this.itemSource,
        tendered,
        "all_card",
        this.customerName
      );

      this.paymentService.terminalRequest(transString).then((res) => {
        this.handleTerminalResponse(res);
      });
    } else {
      this.toast.showError("Invalid tendered amount or transaction status");
    }
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  get paymentRounding() {
    let amountLeftTemp = 0;
    let amountPaidTemp = 0;

    //REDUCING THE AMOUNT PAID
    this.paymentList.forEach((e) => {
      if (e.paymentType != "Tip") {
        amountPaidTemp = amountPaidTemp + e.transTotalAmount;
      }
    });

    if (this.paymentMethodType == "Cash") {
      const amountLeft = this.amountLeft - amountPaidTemp;
      const roundNum = Math.round(amountLeft / 0.05) * 0.05;
      amountLeftTemp = Number(roundNum);

      if (this.roundedAmt == 0) {
        this.roundedAmt = roundNum - amountLeft;
      }
    } else if (this.paymentMethodType != "Tip") {
      amountLeftTemp = this.amountLeft - amountPaidTemp;
      this.roundedAmt = 0;
    } else {
      //nothing
    }

    this.paymentAction.tendered = 0;
    this.screenNumber = "";

    return amountLeftTemp;
  }

  confirmPayments() {
    //restriction == 'Yes'
    if (this.paymentRounding > 0.2 && this.restriction == "No") {
      this.paymentConfirmDialog = true;
    } else {
      this.emitPayments();
    }
  }

  emitPayments() {
    this.$emit("getProceededPaymentsEvent", this.paymentList);
    this.paymentDialog = false;
    this.paymentConfirmDialog = false;
    this.paymentList = [];
    this.clearPaymentScreen();
  }

  clearPaymentScreen() {
    this.amountLeft = 0;
    this.paymentAction.tendered = 0;
    this.paymentAction.needlePoints = 0;
  }

  createTransactionMsg(tranStatus, transAmount, tenderType, customerName) {
    const fs = String.fromCharCode(0x1c);

    let msg = "";

    msg += this.ecrTransStatus(tranStatus);

    msg += fs + this.ecrTransAmount(transAmount);

    msg += fs + this.ecrTenderType(tenderType);

    msg += fs + this.customerReference(customerName);

    //SEND TRANSACTION TO TERMINAL
    return msg;
  }

  ecrTransAmount(transAmount) {
    const netAmt = Number(transAmount * 100);
    const amt = "001" + netAmt;
    return amt;
  }

  ecrTransStatus(status) {
    //00 -> sale 03 -> refund

    let tag = "";

    if (status == "Buyback") {
      tag = "03";
    } else if (status == "Refund") {
      tag = "03";
    } else if (status == "Checkin") {
      tag = "00";
    } else if (status == "Checkout") {
      tag = "00";
    } else {
      tag = "invalid";
    }

    return tag;
  }

  ecrTenderType(tenderType) {
    let tag = "0";

    if (tenderType == "all_card") {
      tag = "0";
    } else if (tenderType == "debit") {
      tag = "1";
    } else if (tenderType == "credit") {
      tag = "2";
    } else if (tenderType == "gift") {
      tag = "3";
    } else if (tenderType == "cash") {
      tag = "7";
    } else {
      //NO THING
    }

    return "002" + tag;
  }

  customerReference(customerName) {
    return "315" + customerName;
  }

  handleTerminalResponse(response) {
    const paymentObj = response;

    const payment: PaymentListType = {
      paymentType: "",
      accountNo: "",
      transTotalAmount: 0,
      terminalId: "",
      authCode: "",
      hostResponse: "",
      transId: "",
      transStatus: "",
      transType: "",
      transDate: "",
      transTime: "",
      transAmount: 0,
      transRef: "",
      entryMode: "",
      giftCardRef: "",
      cardBalance: "",
      tendered: 0,
      change: 0,
      roundOff: 0,
    };


    if (paymentObj[0] != "120" && paymentObj[0] != "130") {
      paymentObj.forEach((e) => {
        const splitTag = e.substr(0, 3);
        const afterIndicator = e.substr(3);

        switch (splitTag) {
          case "000": {
            payment.transStatus = splitTag;
            break;
          }

          case "100": {
            if (afterIndicator == "00") {
              payment.transType = "Sale";
            } else if (afterIndicator == "03") {
              payment.transType = "Refund";
            }

            break;
          }

          case "102": {
            const year = afterIndicator.substr(0, 2);
            const month = afterIndicator.substr(2, 2);
            const day = afterIndicator.substr(4);
            const pyDate = year + ":" + month + ":" + day;

            payment.transDate = pyDate;
            break;
          }

          case "103": {
            const hrs = afterIndicator.substr(0, 2);
            const mint = afterIndicator.substr(2, 2);
            const sec = afterIndicator.substr(4);
            const pyTime = hrs + ":" + mint + ":" + sec;

            payment.transTime = pyTime;
            break;
          }

          case "104": {
            const amount = Number(afterIndicator) / 100;
            payment.transAmount = amount;
            payment.transTotalAmount = amount;
            break;
          }
          case "105": {
            const amount = Number(afterIndicator) / 100;
            this.tipAmountTerminal = amount;
            break;
          }

          case "109": {
           // const amount = Number(afterIndicator) / 100;
           // payment.transTotalAmount = amount;
            break;
          }

          case "112": {
            payment.transRef = afterIndicator;
            break;
          }

          case "300": {
            const cardType = this.transCardType(afterIndicator);
            payment.paymentType = cardType;
            break;
          }

          case "302": {
            payment.accountNo = afterIndicator;
            break;
          }

          case "306": {
            payment.entryMode = afterIndicator;
            break;
          }

          case "400": {
            payment.authCode = afterIndicator;
            break;
          }

          case "402": {
            payment.hostResponse = afterIndicator;
            break;
          }

          case "601": {
            payment.terminalId = afterIndicator;
            break;
          }

          case "133": {
            payment.giftCardRef = afterIndicator;
            break;
          }

          case "409": {
            payment.cardBalance = afterIndicator;
            break;
          }

          default: {
            payment.transStatus = "cancelled";
            break;
          }
        }
      });

      if (paymentObj[0] == "100") {
        payment.transStatus = "DECLINED";
        payment.transTotalAmount = 0;
        payment.transAmount = 0;
        this.toast.showError("Payment DECLINED");
      }

      this.paymentList.push(payment);

      if(this.tipAmountTerminal > 0)
      {
        const tipPayment: PaymentListType = {
              paymentType: "Tip",
              accountNo: payment.accountNo,
              transTotalAmount: this.tipAmountTerminal,
              terminalId: payment.terminalId,
              authCode: payment.authCode,
              hostResponse: payment.hostResponse,
              transId: payment.transId,
              transStatus: payment.transStatus,
              transType: payment.transType,
              transDate: payment.transDate,
              transTime: payment.transTime,
              transAmount: this.tipAmountTerminal,
              transRef: payment.transRef,
              entryMode: payment.entryMode,
              giftCardRef: payment.giftCardRef,
              cardBalance: payment.cardBalance,
              tendered: 0,
              change: 0,
              roundOff: 0,
          };

        this.paymentList.push(tipPayment);
      }

      this.toast.showSuccess("Payment Received from Terminal");
    } else if (paymentObj[0] == "120") {
      this.toast.showError("Payment cancelled");
    } else if (paymentObj[0] == "130") {
      this.toast.showError("Terminal timeout");
    } else {
      //
    }
  }

  transCardType(type) {
    let cardName = "";

    switch (type) {
      case "00":
        cardName = "Debit";
        break;

      case "01":
        cardName = "Visa";
        break;

      case "02":
        cardName = "MasterCard";
        break;

      case "03":
        cardName = "Amex";
        break;

      case "04":
        cardName = "Diners Club";
        break;

      case "05":
        cardName = "Discover Card";
        break;

      case "06":
        cardName = "JCB";
        break;

      case "07":
        cardName = "Union Pay Card";
        break;

      case "08":
        cardName = "Other Credit Card";
        break;

      case "09":
        cardName = "Gift Card";
        break;

      case "10":
        cardName = "Cash";
        break;

      default:
        cardName = "invalid";
        break;
    }

    return cardName;
  }
}
