
import { createRouter,createWebHashHistory } from "vue-router";
import AppShell from '@/pages/wrapper/AppShell.vue';
// import cbIndex from '@/pages/cbIndex/cbIndex.vue';
 import Homepage from '@/pages/homepage/Homepage.vue';
 //import Services from '@/pages/services/Services.vue';
 import Login from '@/pages/login/Login.vue';
 import ForbiddenAccess from '@/pages/forbidden/ForbiddenAccess.vue';
// import Departments from '@/pages/departments/Departments.vue';
// import Items from '@/pages/items/Items.vue';
// import ItemDetails from '@/pages/itemdetails/ItemDetails.vue';
 import Initialization from '@/pages/initialization/Initialization.vue';
 import Stocks from '@/pages/stocks/Stocks.vue';
 import OptionTags from '@/pages/optiontags/OptionTags.vue';
 import PaymentMethods from '@/pages/payment_method/PaymentMethods.vue';
 import Bank from '@/pages/bank/Bank.vue';
 import Banking from '@/pages/banking/Banking.vue';
 import Users from '@/pages/users/Users.vue';
// import CorporatesHead from '@/pages/corporateshead/CorporatesHead.vue';
// import Regions from '@/pages/regions/Regions.vue';
// import Announcements from '@/pages/announcements/Announcements.vue';
// import DryCleaner from '@/pages/drycleaners/DryCleaners.vue';
 import Branches from '@/pages/branches/Branches.vue';
 import ChartOfAccounts from '@/pages/chartofaccounts/ChartOfAccounts.vue';
 import SaleServices from '@/pages/saleservices/SaleServices.vue';
 import PrinterSetups from '@/pages/printersetups/PrinterSetups.vue';
 import ReceiptPrinters from '@/pages/receiptprinter/ReceiptPrinters.vue';
 import RequestedItems from '@/pages/requesteditem/RequestedItems.vue';
 import EncounterVisit from '@/pages/encounter_visit/EncounterVisit.vue';
 import LabList from '@/pages/lab_list/LabList.vue';
// import StoreDryCleaners from '@/pages/storedrycleaner/StoreDryCleaners.vue';
// import Survey from '@/pages/survey/Survey.vue';
// import Coupon from '@/pages/coupons/Coupons.vue';
// import Backroom from '@/pages/backroom/Backroom.vue';
// import OnRack from '@/pages/backroom/OnRack.vue';
// import StoreExpenses from '@/pages/storeexpenses/StoreExpenses.vue';
// import OnProgress from '@/pages/backroom/OnProgress.vue';
// import CorporateBranches from '@/pages/corporatebranches/CorporateBranches.vue';
// import ProjectedSales from '@/pages/projectedsales/ProjectedSales.vue';
// import UpChargeLists from '@/pages/upchargelists/UpChargeLists.vue';
// import Associates from '@/pages/associates/Associates.vue';
 import Profilers from '@/pages/profilers/Profilers.vue';
 import PosReceipt from '@/pages/pos_receipt/PosReceipt.vue';
 import JournalVoucher from '@/pages/journal_voucher/JournalVoucher.vue';
 import OtherVoucher from '@/pages/other_voucher/OtherVoucher.vue';
 import UserBalance from '@/pages/user_balance/UserBalance.vue';
 import ImportStock from '@/pages/import_stock/ImportStock.vue';
 import OpenHead from '@/pages/open_head/OpenHead.vue';
 import Invoice from '@/pages/invoice/Invoice.vue';
 import SalesReceipt from '@/pages/sales_receipt/SalesReceipt.vue';
 import PosPurchase from '@/pages/pos_purchase/PosPurchase.vue';
 import { useStore } from "./store";

 import AppCheckoutShell from '@/pages/wrapper/AppCheckoutShell.vue';
// import ScheduleAssistance from '@/pages/schedule/ScheduleAssistance.vue';
// import HoursApproval from '@/pages/hoursApproval/HoursApproval.vue';
// import Attendance from '@/pages/attendance/Attendance.vue';
// import OnReady from '@/pages/backroom/OnReady.vue';
 import TransactionReceipt from '@/pages/transaction/TransactionReceipt.vue';
 import Privileges from '@/pages/privileges/Privileges.vue';
 import Consultancy from '@/pages/consultancy/Consultancy.vue';
// import CashRegister from '@/pages/cashRegister/CashRegister.vue';
// import ManagerCashRegister from '@/pages/cashRegister/ManagerCashRegister.vue';
// import CheckIn from '@/pages/checkIn/CheckIn.vue';
// import ScheduleList from '@/pages/schedulelist/ScheduleList.vue';
 import Report from '@/pages/reports/Report.vue';
 import SalesRefundReport from '@/pages/reports/SalesRefundReport.vue';
// import AssociateReport from '@/pages/associateReport/AssociateReport.vue';
// import PriceList from '@/pages/pricelist/PriceList.vue';




const ifAuthenticated = (to, __, next) => {

  const u = userPermission(to.name);
  const store = useStore();
  if (store.getters.isAuthenticated == '') next({ path: '/login' })
  else if (u == false) next({ path: '/forbidden' })
  else next()
}

const ifNotAuthenticated = (_, __, next) => {

  const store = useStore();
  if (store.getters.isAuthenticated == '') next()
  else next({ path: '/store/dashboard' })

}

 
const routes = [
  { path: '/', redirect: "/store/dashboard", beforeEnter: ifAuthenticated },
  { path: '/login', name: 'login', component: Login, beforeEnter: ifNotAuthenticated },
  { path: '/forbidden', name: 'forbidden', component: ForbiddenAccess },
 // { path: '/cb_index', name: 'cbIndex', component: cbIndex},
  { path: '/pos', name: 'Pos', component: PosReceipt, beforeEnter: ifAuthenticated},
  { path: '/purchasing', name: 'Purchasing', component: PosPurchase, beforeEnter: ifAuthenticated},
 // { path: '/update-receipt/:receiptID', name: 'update-receipt', component: CheckIn, beforeEnter: ifAuthenticated, props: true },
  // {
  //   path: '/backroom',
  //   redirect: "/backroom/on-rack",
  //   name: 'backroom',
  //   component: Backroom,
  //   beforeEnter: ifAuthenticated,
  //   children: [
  //     { path: 'on-rack', name: 'on-rack', component: OnRack },
  //     { path: 'on-progress', name: 'on-progress', component: OnProgress },
  //     { path: 'on-ready', name: 'on-ready', component: OnReady },
  //   ]
  // },
   {
    path: '/process',
    name: 'Transaction Receipt',
    component: AppCheckoutShell,
    beforeEnter: ifAuthenticated,
    children: [
       { path: 'transactions', name: 'Transaction Receipt', component: TransactionReceipt },
     ]
    },
  {
    path: '/store',
    name: 'app_shell',
    component: AppShell,
    beforeEnter: ifAuthenticated,
    children: [
      // { path: 'hours-approval', name: 'HoursApproval', component: HoursApproval },
      // { path: 'schedule', name: 'schedule', component: ScheduleAssistance },
      { path: 'initialization', name: 'Initialization', component: Initialization },
       { path: 'stores', name: 'Branches', component: Branches },
       { path: 'banks', name: 'Banks', component: Bank },
       { path: 'banking', name: 'Banking', component: Banking },
      // { path: 'services', name: 'services', component: Services },
       { path: 'dashboard', name: 'Dashboard', component: Homepage },
       { path: 'sale-services', name: 'Sale Services', component: SaleServices },
       { path: 'stocks', name: 'Stocks', component: Stocks },
       { path: 'chart-of-accounts', name: 'Chart Of Accounts', component: ChartOfAccounts },
       { path: 'option-tag', name: 'Option Tags', component: OptionTags,beforeEnter: ifAuthenticated, },
       { path: 'payment-method', name: 'Payment Methods', component: PaymentMethods },
       { path: 'journal-voucher', name: 'Journal Voucher', component: JournalVoucher },
       { path: 'other-voucher', name: 'Other Voucher', component: OtherVoucher },
       { path: 'import-stock', name: 'Import Stock', component: ImportStock },
       { path: 'user-balance', name: 'User Balance', component: UserBalance },
       { path: 'open-head', name: 'Open Head', component: OpenHead },
       { path: 'invoice', name: 'Invoice', component: Invoice },
       { path: 'sales-receipt', name: 'Sales/Refund Receipt', component: SalesReceipt },
       { path: 'users', name: 'Users', component: Users },
       { path: 'lab-settings', name: 'Laboratory Settings', component: LabList },
       { path: 'privileges', name: 'Privileges', component: Privileges },
      // { path: 'corporates-head', name: 'CorporatesHead', component: CorporatesHead },
      // { path: 'regions', name: 'Regions', component: Regions },
      // { path: 'dry-cleaner', name: 'DryCleaner', component: DryCleaner },
      // { path: 'announcement', name: 'Announcements', component: Announcements },
      // { path: 'store-dry-cleaner', name: 'StoreDryCleaners', component: StoreDryCleaners },
      { path: 'receipt-printer', name: 'Receipt Content', component: ReceiptPrinters},
      { path: 'printer-setup', name: 'Printer Setup', component: PrinterSetups},
      { path: 'requested-items', name: 'Requested Items', component: RequestedItems},
      // { path: 'survey', name: 'Survey', component: Survey },
      // { path: 'coupon', name: 'Coupon', component: Coupon },
      // { path: 'store-expense', name: 'StoreExpenses', component: StoreExpenses },
      // { path: 'corporate-braches', name: 'CorporateBranches', component: CorporateBranches },
      // { path: 'project-sales', name: 'ProjectSales', component: ProjectedSales },
      // { path: 'up-charge-lists', name: 'UpChargeLists', component: UpChargeLists },
      // { path: 'associates', name: 'Associates', component: Associates },
       { path: 'profilers', name: 'Profilers', component: Profilers },
      // { path: 'attendance', name: 'Attendance', component: Attendance },
      // { path: 'cash-register', name: 'CashRegister', component: CashRegister },
      // { path: 'manager-cash-register', name: 'ManagerCashRegister', component: ManagerCashRegister },
      // { path: 'schedule-list', name: 'ScheduleList', component: ScheduleList },
      // { path: 'report', name: 'Report', component: Report },
      // { path: 'associate-report', name: 'AssociateReport', component: AssociateReport },
       { path: 'front-desk', name: 'Encounter Visit', component: EncounterVisit },
       { path: 'consultancy', name: 'Consultancy', component: Consultancy },
       { path: 'reports', name: 'Report', component: Report },
       { path: 'sales-refund-reports', name: 'Sales/Refund Report', component: SalesRefundReport },
    ]
  }
]



const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const userPermission = (permission) => {

  const store = useStore();

  const pL =  JSON.parse(store.getters.gerUserPermissions);

  const f = pL.filter(e =>  e.name == permission);

  let res = false;

    if(f.length > 0)
    {
      res = true;
    }

    return res;
}


// const router = createRouter({
//   history: createWebHistory(''),
//   routes,
// });

export default router;