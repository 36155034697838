
import { Options, Vue } from "vue-class-component";
import VisitService from "../../service/VisitService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";
import SearchFilter from "../../components/SearchFilter.vue";
import AutoComplete from "primevue/autocomplete";
import PatientConsultancy from "../../components/PatientConsultancy.vue";
import moment from "moment";
import { useStore, ActionTypes } from "../../store";

@Options({
    components: {
        SearchFilter,
        PatientConsultancy,
        AutoComplete
    }
})
export default class Consultancy extends Vue {
    private lists = [];
    private store = useStore();
    private componentName = "";
    private dialogTitle = "";
    private keyword = "";
    private filterDialog = false;
    private toast;
    private goToFirstLink = 0;
    private statement = "";
    private patientID = 0;
    private visitService;
    private patientDialogStatus = false;
    private productDialog = false;
    private submitted = false;
    private checkPagination = true;
    private totalRecords = 0;
    private limit = 0;
    private home = { icon: "pi pi-home", to: "/" };
    private storeList = [];
    private patientList = [];
    private items = [{ label: "Consultancy" }];

    private selectedStore = {
        id: 0
    };

    private item = {
        id: 0,
        selectedProfile: "",
        doctorName: "",
        notes: "",
        visitDate: "",
        patientID: 0,
        visitFee: 0
    };

    private itemFilter = {
        keyword: "",
        filterType: "today",
        storeID: 0,
        date1: "",
        date2: "",
        type: "DBV"
    };

    //CALLING WHEN PAGINATION BUTTON CLICKS
    onPage(event) {
        this.loadList(event.first);
    }

    //DEFAULT METHOD OF TYPE SCRIPT
    created() {
        this.visitService = new VisitService();
        this.toast = new Toaster();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.loadList(0);
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.clearItem();
        this.dialogTitle = "Register New Patient";
        this.patientID = 0;
        this.patientDialogStatus = true;
    }

    openVisitDialog() {
        if (this.item.patientID == 0 || this.item.selectedProfile == "") {
            this.toast.showError("Please choose any Patients Profile");
        } else {
            this.dialogTitle =
                "Schedule Appointment for " + this.item.selectedProfile;
            this.productDialog = true;
        }
    }

    editPatient() {
        if (this.item.patientID == 0 || this.item.selectedProfile == "") {
            this.toast.showError("Please choose any Patients Profile");
        } else {
            this.dialogTitle = "Update Patient";
            this.patientID = this.item.patientID;
            this.patientDialogStatus = true;
        }
    }

    //CLOSE THE ITEM DAILOG BOX
    hideDialog() {
        this.productDialog = false;
        this.submitted = false;
    }

    //ADD OR UPDATE THE ITEM VIA HTTP
    saveVisitItem() {
        this.submitted = true;
        if (this.item.visitDate != "") {
            this.item.visitDate = this.formatDateTimeReverse(
                this.item.visitDate
            );
            if (this.item.id != 0) {
                this.visitService.updateItem(this.item).then(res => {
                    this.loadList(this.goToFirstLink);
                    //SHOW NOTIFICATION
                    this.toast.handleResponse(res);
                });
            } else {
                this.visitService.saveItem(this.item).then(res => {
                    this.goToFirstLink = 0;
                    this.loadList(this.goToFirstLink);
                    //SHOW NOTIFICATION
                    this.toast.handleResponse(res);
                });
            }

            this.productDialog = false;
            this.clearItem();
        } else {
            this.toast.showError("PLease choose visit date");
        }
    }

    //OPEN DIALOG BOX TO EDIT
    editIem(data) {
        this.submitted = false;
        this.dialogTitle =
            "Update Schedule Appointment for " +
            data.patient_details.first_name +
            " " +
            data.patient_details.last_name;
        this.productDialog = true;

        this.item.selectedProfile =
            data.patient_details.first_name +
            " " +
            data.patient_details.last_name;
        this.item.doctorName = data.doctor_name;
        this.item.notes = data.notes == null ? "" : data.notes;
        this.item.visitDate = this.formatDateTime(data.visit_date);
        this.item.patientID = data.patient_details.id;
        this.item.visitFee = data.visit_fee;
        this.item.id = data.id;
    }

    //FETCH THE DATA FROM SERVER
    loadList(page) {
        this.visitService
            .getItems(this.itemFilter, this.item.patientID, page)
            .then(data => {
                this.lists = data.records;
                this.totalRecords = data.totalRecords;
                this.storeList = data.stores;
                this.limit = data.limit;
                this.statement = data.statement;
            });
    }

    clearItem() {
        this.item = {
            id: 0,
            selectedProfile: "",
            doctorName: "",
            notes: "",
            patientID: 0,
            visitDate: "",
            visitFee: 0
        };
    }

    searchPatient(event) {
        setTimeout(() => {
            this.visitService.searchPatient(event.query.trim()).then(data => {
                this.patientList = data.records;
            });
        }, 200);
    }

    savePatient(event) {
        const profileInfo = event.value;
        this.item.selectedProfile =
            profileInfo.first_name + " " + profileInfo.last_name;
        this.item.patientID = profileInfo.id;
    }

    formatDate(d) {
        const dt = new Date(d);
        return moment(dt).format("DD-MMM-YYYY");
    }

    closeConsultantScreenEvent(obj) {
        this.componentName = '';
    }

    patientAge(d) {
        let age = "";
        const dt = new Date(d);
        const years = moment().diff(dt, "years", false);
        age = years + " Year";

        if (years == 0) {
            const months = moment().diff(dt, "months", false);
            age = months + " Month";

            if (months == 0) {
                const days = moment().diff(dt, "days", false);
                age = days + " Day";
            }
        }

        return age;
    }

    formatDateTime(dt) {
        return moment(new Date(dt)).format("DD-MMM-YYYY hh:mm A");
    }

    formatDateTimeReverse(dt) {
        return moment(new Date(dt)).format("YYYY-MM-DD  hh:mm");
    }

    openFilterDialog() {
        this.dialogTitle = "Filter Vouchers";
        this.filterDialog = true;
    }

    updateFilterStatus(obj) {
        if (obj != null && obj.loading == "Yes") {
            this.itemFilter.filterType = obj.filterName.value;
            this.itemFilter.storeID = obj.storeID.id;
            this.itemFilter.date1 = obj.date1;
            this.itemFilter.date2 = obj.date2;
            this.itemFilter.keyword = "";
            this.goToFirstLink = 0;
            this.loadList(this.goToFirstLink);
        }
        this.filterDialog = false;
    }

    searchPatientID() {
        if (this.item.selectedProfile == "") {
             this.clearItem();
             this.loadList(0);
        } else {
            this.loadList(0);
        }
    }

    openConsultancy(patientID) {
        this.clearItem();
        this.componentName = "PatientConsultancy";
        this.store.dispatch(ActionTypes.GET_RECEIPT_ID, patientID);
    }
}
