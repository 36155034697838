<template>
    <div class="p-grid">
        <h6 class="heading-style p-col-12 p-m-0">
            <i class="pi pi-check-circle"></i> Patient General Info <br />
            <small
                >Measure patients generalInfo details and enter inside these
                fields</small
            >
        </h6>
        <div class="p-col-8 p-m-0 p-mt-2">
            <div class="">
               <DataTable
                :value="recordList"
                :scrollable="true"
                class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
            >
                <template #empty>
                    <div class="p-text-center p-p-3">No records found</div>
                </template>
                <Column field="" header="Date/Time">
                    <template #body="slotProps">
                        {{formatDateTime(slotProps.data.visitDate)}}
                    </template>
                </Column>
                <Column field="visitFee" header="Fee"></Column>
                <Column field="doctorName" header="Doctor Name"></Column>
                <Column  :exportable="false" header="Action">
                    <template #body="slotProps">
                        <Button
                            icon="pi pi-eye"
                            class="p-button-rounded p-button-warning"
                            @click="patientHistory(slotProps.data.visitDate)"
                        />
                    </template>
                </Column>
            </DataTable>
            </div>
        </div>
        <div class="p-col-4 p-mt-1 sec-2-height">
            <h6 class="tab-headings p-py-2 p-px-2" ><i class="pi pi-check-circle"></i> Patient Vitals History</h6>
            <template v-for="(item, index) in sortedVitalRecords" :key="index">
                <Panel
                    :header="index"
                    :toggleable="true"
                    class="p-p-0"
                    :collapsed="getTodaysDate != index"
                >
                    <template v-for="(i, k) in item" :key="k">
                        <table class="table table-striped">
                            <tr class="list-row-style">
                                <td class="p-p-1">SNO : {{ k + 1 }}</td>
                                <td class="p-p-1 p-text-right">
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Weight (KG)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.weight }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Respiration rate (Min)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.respiration }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Height (CM)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.height }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">O2 (%)</td>
                                <td class="p-text-right p-p-1">{{ i.o2 }}</td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Body Temperature (C)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.temperature }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Blood pressure (mmHg)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.pressure }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Pulse rate (BPM)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.pulse }}
                                </td>
                            </tr>
                        </table>
                    </template>
                </Panel>
            </template>

            <h6 class="tab-headings p-py-2 p-px-2" ><i class="pi pi-check-circle"></i> Patient Medications History </h6>
            <template v-for="(item, index) in sortedMedicationRecords" :key="index">
                <Panel
                    :header="index"
                    :toggleable="true"
                    class="p-p-0"
                    :collapsed="getTodaysDate != index"
                >
                    <template v-for="(i, k) in item" :key="k">
                        <table class="table table-striped">
                            <tr class="list-row-style">
                                <td class="p-p-1">SNO : {{ k + 1 }}</td>
                                <td class="p-p-1 p-text-right">
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Drug Name</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.drugs.productName }} (
                                    {{ i.drugs.generic }} )
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Dose</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.dose }} {{ i.doseUnit }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Frequency</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.frequency }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Duration</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.duration }} {{ i.durationUnit }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Additional Instructions</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.additionalInstructions }}
                                </td>
                            </tr>
                        </table>
                    </template>
                </Panel>
            </template>

            <h6 class="tab-headings p-py-2 p-px-2" ><i class="pi pi-check-circle"></i> Patient Lab Results History</h6>
            <template v-for="(item, index) in sortedLabRecords" :key="index">
                <Panel
                    :header="index"
                    :toggleable="true"
                    class="p-p-0"
                     :collapsed="getTodaysDate != index"
                >
                    <template v-for="(i, k) in item" :key="k">
                        <table class="table table-striped">
                            <tr class="list-row-style">
                                <td class="p-p-1">SNO : {{ k + 1 }}</td>
                                <td class="p-p-1 p-text-right">
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Category Name</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.optionName }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Test Name</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.testName }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Status</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.assignStatus }}
                                </td>
                            </tr>
                        </table>
                    </template>
                </Panel>
            </template>

            <h6 class="tab-headings p-py-2 p-px-2" ><i class="pi pi-check-circle"></i> Patient Docs History</h6>
            <template v-for="(item, index) in sortedPatientRecords" :key="index">
                <Panel
                    :header="index"
                    :toggleable="true"
                    class="p-p-0"
                    :collapsed="getTodaysDate != index"
                >
                    <template v-for="(i, k) in item" :key="k">
                        <table class="table table-striped">
                            <tr class="list-row-style">
                                <td class="p-p-1">SNO : {{ k + 1 }}</td>
                                <td class="p-p-1 p-text-right">
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">File Name</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.fileName }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Download File</td>
                                <td class="p-text-right p-p-1">
                                    <Button class="p-button-success p-p-1" @click="downloadPatientDoc(i.id,i.fileName+i.docName)" icon="pi pi-download" />
                                </td>
                            </tr>
                        </table>
                    </template>
                </Panel>
            </template> 
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import Confirmation from "../components/Confirmation.vue";
import { camelCase } from "lodash";
import moment from "moment";
import PatientGeneralService from "../service/PatientGeneralService.js";

interface VisitTypes{
    visitDate:string
}

interface GeneralTypes{
    pDate:string
}

@Options({
    components: {
        Confirmation
    }
})
export default class GeneralInfo extends Vue {
    private recordList : VisitTypes [] = [];
     private assessments : GeneralTypes [] = [];
    private labOrders : GeneralTypes [] = [];
    private medications : GeneralTypes [] = [];
    private patientDocs  : GeneralTypes [] = [];
    private generalService;
    private toast;
    private vuexStore = useStore();


    created() {
        this.generalService = new PatientGeneralService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    loadList() {
        this.generalService.getVisits(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
            this.patientHistory(this.recordList[0].visitDate);
        });
    } 
    
    patientHistory(d) {
        const dt = this.formatDateHistory(d);
        this.generalService.getPatientHistory(this.patientID,dt).then(data => {
            const res = this.camelizeKeys(data);
            this.assessments = res.assessments;
            this.labOrders   = res.labOrders;
            this.patientDocs = res.patientDocs;
            this.medications = res.medications;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

    formatDateTime(d)
    {
        return moment(d).format('DD-MMM-YYYY HH:mm A');
    }
    
    formatDateHistory(d)
    {
        return moment(d).format('YYYY-MM-DD');
    }

    get sortedVitalRecords() {
        let sortedList = {};

        this.assessments.forEach(e => {

            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get sortedMedicationRecords() {
        let sortedList = {};

        this.medications.forEach(e => {

            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get sortedLabRecords() {
        let sortedList = {};

        this.labOrders.forEach(e => {

            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get sortedPatientRecords() {
        let sortedList = {};

        this.patientDocs.forEach(e => {

            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

     downloadPatientDoc(id,image) {
        this.generalService.downloadPatientDoc(id).then(res => {

            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', image);
            document.body.appendChild(link);
            link.click();
        });
    }
}
</script>

<style scoped>
.heading-style {
    background-color: green;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
    border-radius: 2rem;
}

.sec-2-height {
    height: 75vh;
    min-height: 75vh;
    overflow-y: scroll;
    background-color: #f7f7f7;
    border-left: 1px solid #ccc;
}

.list-row-style {
    background-color: #818181;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
}

.tab-headings
{
    background-color: #1a5692;
    color: #fff;
}
</style>
