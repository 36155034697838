<template>
    <div class="p-grid">
        <h6 class="heading-style p-col-12 p-m-0">
            <i class="pi pi-check-circle"></i> Patient Next Visit <br />
            <small
                >Patients Next Visit details and enter inside these
                fields</small
            >
        </h6>
        <div class="p-col-8 p-m-0 p-mt-2">
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-field">
                        <label
                            for="scheduleDateTime"
                            :class="{
                                'p-error': v$.scheduleDateTime.$invalid && submitted
                            }"
                            >Schedule Date/Time</label
                        >
                        <Calendar  v-model="v$.scheduleDateTime.$model" :inline="true" :class="{'p-invalid': v$.scheduleDateTime.$invalid && submitted}" :showTime="true" hourFormat="12" />
                        <small
                            v-if="
                                (v$.scheduleDateTime.$invalid && submitted) ||
                                    v$.scheduleDateTime.$pending.$response
                            "
                            class="p-error"
                            >{{
                                v$.scheduleDateTime.required.$message.replace(
                                    "Value",
                                    "Schedule Date/Time"
                                )
                            }}</small
                        >
                    </div>
                </div>
                <div class="p-col-12 p-text-center">
                    <Button
                        type="button"
                        style="width: 15rem"
                        label="Save"
                        icon="pi pi-check-circle"
                        class="p-button-primary p-button-lg"
                        @click.prevent="saveItem(!v$.$invalid)"
                    />
                </div>
            </div>
        </div>
        <div class="p-col-4 p-mt-1 sec-2-height">
            <template v-for="(item, index) in sortedRecords" :key="index">
                <Panel
                    :header="index"
                    :toggleable="true"
                    class="p-p-0"
                    :collapsed="getTodaysDate!= index"
                >
                    <template v-for="(i, k) in item" :key="k">
                        <table class="table table-striped">
                            <tr class="list-row-style">
                                <td class="p-p-1">SNO : {{ k + 1 }}</td>
                                <td class="p-p-1 p-text-right">
                                    <Button
                                        type="button"
                                        icon="pi pi-times"
                                        class="p-button-danger p-p-1"
                                        @click.prevent="deleteItem(i.id)"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Schedule Date / Time</td>
                                <td class="p-text-right p-p-1">
                                    {{ visitFormatDate(i.scheduleDateTime)}}
                                </td>
                            </tr>
                        </table>
                    </template>
                </Panel>
            </template>
        </div>
    </div>

    <component
        v-if="componentName != ''"
        v-bind:is="componentName"
        v-on:updateConfirmationStatus="updateConfirmation"
    >
    </component>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import NextVisitService from "../service/NextVisitService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";

interface recordTypes {
    pDate: Date;
}

@Options({
    components: {
        Confirmation
    }
})
export default class NextVisit extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private pService;
    private submitted = false;
    private toast;
    private componentName = "";
    private vuexStore = useStore();

    private state = reactive({
        scheduleDateTime: '',
    });

    private validationRules = {
        scheduleDateTime: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    created() {
        this.pService = new NextVisitService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {

            this.state.scheduleDateTime = this.formatDateTimeReverse(
                this.state.scheduleDateTime
            );

            this.pService
                .save(this.state, this.patientID)
                .then(res => {
                    this.clearItems();
                    this.toast.handleResponse(res);
                    this.loadList();
                });
        }
    }

    clearItems() {
        this.submitted = false;
        this.state.scheduleDateTime = '';
    }

    loadList() {
        this.pService.get(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

     get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach(e => {
            
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

           
            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    visitFormatDate(d)
    {
        return moment(new Date(d)).format("DD-MMM-YYYY hh:mm A");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.delete(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }

     formatDateTimeReverse(dt) {
        return moment(new Date(dt)).format("YYYY-MM-DD  hh:mm");
    }
}
</script>

<style scoped>
.heading-style {
    background-color: green;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
    border-radius: 2rem;
}

.sec-2-height {
    height: 75vh;
    min-height: 75vh;
    overflow-y: scroll;
    background-color: #f7f7f7;
    border-left: 1px solid #ccc;
}

.list-row-style {
    background-color: #818181;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
}
</style>
