
import { Options, mixins } from "vue-class-component";
import UtilityOptions from "../../mixins/UtilityOptions";

export default class Report extends mixins(UtilityOptions) {
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Report" }];
  private reportList = [
    {
      label: "Sales/Refund Report",
      image: require("@/assets/menu-icons/employee.png").default,
      to: "/store/sales-refund-reports",
      description:"Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Time Schedule Report",
      image: require("@/assets/menu-icons/time_report.png").default,
      to: "/store/services",
      description:
        "Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Flash Report",
      image: require("@/assets/menu-icons/flash_report.png").default,
      to: "/store/departments",
      description:
        "Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Dry Cleaners Invoice",
      image: require("@/assets/menu-icons/dryclean.png").default,
      to: "/store/dry-clean-invoice-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Coupon Report",
      image: require("@/assets/menu-icons/discount.png").default,
      to: "/store/coupon-report",
      description:
        "Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Product Sales Report",
      image: require("@/assets/menu-icons/item-details.png").default,
      to: "/store/product-sales-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Store Super Crease Report",
      image: require("@/assets/menu-icons/supercrease.png").default,
      to: "/store/super-crease-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Corporate Report",
      image: require("@/assets/menu-icons/corporate.png").default,
      to: "/store/corporate-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Customer Related Report",
      image: require("@/assets/menu-icons/customers.png").default,
      to: "/store/customer-related-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Weekly Cash Report",
      image: require("@/assets/menu-icons/pricelist.png").default,
      to: "/store/weekly-payment-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Customer Corporate Type",
      image: require("@/assets/menu-icons/upchargelist.png").default,
      to: "/store/customer-corporate-type-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Customer Report",
      image: require("@/assets/menu-icons/schedule.png").default,
      to: "/store/customer-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Referral Report",
      image: require("@/assets/menu-icons/settings-pos.png").default,
      to: "/store/referral-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Sales Performance Report",
      image: require("@/assets/menu-icons/performance.png").default,
      to: "/store/top-performance-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Productivity Report",
      image: require("@/assets/menu-icons/productivity.png").default,
      to: "/store/productivity-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    },
    {
      label: "Refunded Items",
      image: require("@/assets/menu-icons/refund.png").default,
      to: "/store/refund-report",
      description:
        "Here goes description there Here goes description thereHere goes description there",
    },
    {
      label: "Buy Backs",
      image: require("@/assets/menu-icons/buyback.png").default,
      to: "/store/buyback-report",
      description:
        "Here goes description there Here goes description thereHere goes description there.",
    }
  ];
}
