<template>
    <div class="p-grid">
        <h6 class="heading-style p-col-12 p-m-0">
            <i class="pi pi-check-circle"></i> Patient Assessments <br />
            <small
                >Measure patients assessments details and enter inside these
                fields</small
            >
        </h6>
        <div class="p-col-8 p-m-0 p-mt-2">
            <div class="p-grid">
                <div class="p-col-6">
                    <div class="p-field">
                        <label
                            for="weight"
                            :class="{
                                'p-error': v$.weight.$invalid && submitted
                            }"
                            >Weight (KG)</label
                        >
                        <InputNumber
                            :maxFractionDigits="2"
                            :minFractionDigits="2"
                            :useGrouping="false"
                            id="weight"
                            v-model="v$.weight.$model"
                            :class="{
                                'p-invalid': v$.weight.$invalid && submitted
                            }"
                        />
                        <small
                            v-if="
                                (v$.weight.$invalid && submitted) ||
                                    v$.weight.$pending.$response
                            "
                            class="p-error"
                            >{{
                                v$.weight.required.$message.replace(
                                    "Value",
                                    "Weight"
                                )
                            }}</small
                        >
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label
                            for="respiration"
                            :class="{
                                'p-error': v$.respiration.$invalid && submitted
                            }"
                            >Respiration Rate (Min)</label
                        >
                        <InputNumber
                            :maxFractionDigits="2"
                            :minFractionDigits="2"
                            :useGrouping="false"
                            id="respiration"
                            v-model="v$.respiration.$model"
                            :class="{
                                'p-invalid':
                                    v$.respiration.$invalid && submitted
                            }"
                        />
                        <small
                            v-if="
                                (v$.respiration.$invalid && submitted) ||
                                    v$.respiration.$pending.$response
                            "
                            class="p-error"
                            >{{
                                v$.respiration.required.$message.replace(
                                    "Value",
                                    "Respiration Rate"
                                )
                            }}</small
                        >
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label
                            for="height"
                            :class="{
                                'p-error': v$.height.$invalid && submitted
                            }"
                            >Height (CM)</label
                        >
                        <InputNumber
                            :maxFractionDigits="2"
                            :minFractionDigits="2"
                            :useGrouping="false"
                            id="height"
                            v-model="v$.height.$model"
                            :class="{
                                'p-invalid': v$.height.$invalid && submitted
                            }"
                        />
                        <small
                            v-if="
                                (v$.height.$invalid && submitted) ||
                                    v$.height.$pending.$response
                            "
                            class="p-error"
                            >{{
                                v$.height.required.$message.replace(
                                    "Value",
                                    "Height"
                                )
                            }}</small
                        >
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label
                            for="o2"
                            :class="{
                                'p-error': v$.o2.$invalid && submitted
                            }"
                            >O2 (%)</label
                        >
                        <InputNumber
                            :maxFractionDigits="2"
                            :minFractionDigits="2"
                            :useGrouping="false"
                            id="o2"
                            v-model="v$.o2.$model"
                            :class="{
                                'p-invalid': v$.o2.$invalid && submitted
                            }"
                        />
                        <small
                            v-if="
                                (v$.o2.$invalid && submitted) ||
                                    v$.o2.$pending.$response
                            "
                            class="p-error"
                            >{{
                                v$.o2.required.$message.replace("Value", "O2")
                            }}</small
                        >
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label
                            for="temperature"
                            :class="{
                                'p-error': v$.temperature.$invalid && submitted
                            }"
                            >Body Temperature (C)</label
                        >
                        <InputNumber
                            :maxFractionDigits="2"
                            :minFractionDigits="2"
                            :useGrouping="false"
                            id="temperature"
                            v-model="v$.temperature.$model"
                            :class="{
                                'p-invalid':
                                    v$.temperature.$invalid && submitted
                            }"
                        />
                        <small
                            v-if="
                                (v$.temperature.$invalid && submitted) ||
                                    v$.temperature.$pending.$response
                            "
                            class="p-error"
                            >{{
                                v$.temperature.required.$message.replace(
                                    "Value",
                                    "Body Temperature"
                                )
                            }}</small
                        >
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label
                            for="pressure"
                            :class="{
                                'p-error': v$.pressure.$invalid && submitted
                            }"
                            >Blood pressure (mmHg)</label
                        >
                        <InputNumber
                            :maxFractionDigits="2"
                            :minFractionDigits="2"
                            :useGrouping="false"
                            id="pressure"
                            v-model="v$.pressure.$model"
                            :class="{
                                'p-invalid': v$.pressure.$invalid && submitted
                            }"
                        />
                        <small
                            v-if="
                                (v$.pressure.$invalid && submitted) ||
                                    v$.pressure.$pending.$response
                            "
                            class="p-error"
                            >{{
                                v$.pressure.required.$message.replace(
                                    "Value",
                                    "Blood pressure"
                                )
                            }}</small
                        >
                    </div>
                </div>
                <div class="p-col-6">
                    <div class="p-field">
                        <label
                            for="pulse"
                            :class="{
                                'p-error': v$.pulse.$invalid && submitted
                            }"
                            >Pulse rate (BPM)</label
                        >
                        <InputNumber
                            :maxFractionDigits="2"
                            :minFractionDigits="2"
                            :useGrouping="false"
                            id="pulse"
                            v-model="v$.pulse.$model"
                            :class="{
                                'p-invalid': v$.pulse.$invalid && submitted
                            }"
                        />
                        <small
                            v-if="
                                (v$.pulse.$invalid && submitted) ||
                                    v$.pulse.$pending.$response
                            "
                            class="p-error"
                            >{{
                                v$.pulse.required.$message.replace(
                                    "Value",
                                    "Pulse rate (BPM)"
                                )
                            }}</small
                        >
                    </div>
                </div>
                <div class="p-col-12 p-text-center">
                    <Button
                        type="button"
                        style="width: 15rem"
                        label="Save"
                        icon="pi pi-check-circle"
                        class="p-button-primary p-button-lg"
                        @click.prevent="saveItem(!v$.$invalid)"
                    />
                </div>
            </div>
        </div>
        <div class="p-col-4 p-mt-1 sec-2-height">
            <template v-for="(item, index) in sortedRecords" :key="index">
                <Panel
                    :header="index"
                    :toggleable="true"
                    class="p-p-0"
                    :collapsed="getTodaysDate!= index"
                >
                    <template v-for="(i, k) in item" :key="k">
                        <table class="table table-striped">
                            <tr class="list-row-style">
                                <td class="p-p-1">SNO : {{ k + 1 }}</td>
                                <td class="p-p-1 p-text-right">
                                    <Button
                                        type="button"
                                        icon="pi pi-times"
                                        class="p-button-danger p-p-1"
                                        @click.prevent="deleteItem(i.id)"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Weight (KG)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.weight }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Respiration rate (Min)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.respiration }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Height (CM)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.height }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">O2 (%)</td>
                                <td class="p-text-right p-p-1">{{ i.o2 }}</td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Body Temperature (C)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.temperature }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Blood pressure (mmHg)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.pressure }}
                                </td>
                            </tr>
                            <tr>
                                <td class="p-p-1">Pulse rate (BPM)</td>
                                <td class="p-text-right p-p-1">
                                    {{ i.pulse }}
                                </td>
                            </tr>
                        </table>
                    </template>
                </Panel>
            </template>
        </div>
    </div>

    <component
        v-if="componentName != ''"
        v-bind:is="componentName"
        v-on:updateConfirmationStatus="updateConfirmation"
    >
    </component>

    <!-- <Confirmation
        :confirmDetail="{
            statusDialog: this.statusDialog,
            dialogTitle: this.dialogTitle
        }"
        v-on:updateConfirmationStatus="updateConfirmation"
    /> -->
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import PatientConsultationService from "../service/PatientConsultationService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";

interface recordTypes {
    pDate: Date;
}

@Options({
    components: {
        Confirmation
    }
})
export default class Assessments extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private pService;
    private submitted = false;
    private toast;
    private componentName = "";
    private vuexStore = useStore();

    private state = reactive({
        weight: 0,
        height: 0,
        o2: 0,
        respiration: 0,
        temperature: 0,
        pressure: 0,
        pulse: 0
    });

    private validationRules = {
        weight: {
            required
        },
        height: {
            required
        },
        o2: {
            required
        },
        respiration: {
            required
        },
        temperature: {
            required
        },
        pressure: {
            required
        },
        pulse: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    created() {
        this.pService = new PatientConsultationService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            this.pService
                .saveAssessment(this.state, this.patientID)
                .then(res => {
                    this.clearItems();
                    this.toast.handleResponse(res);
                    this.loadList();
                });
        }
    }

    clearItems() {
        this.state.weight = 0;
        this.state.height = 0;
        this.state.o2 = 0;
        this.state.respiration = 0;
        this.state.temperature = 0;
        this.state.pressure = 0;
        this.state.pulse = 0;
    }

    loadList() {
        this.pService.getAssessment(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

     get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach(e => {
            
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

           
            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.deleteAssessment(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }
}
</script>

<style scoped>
.heading-style {
    background-color: green;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
    border-radius: 2rem;
}

.sec-2-height {
    height: 75vh;
    min-height: 75vh;
    overflow-y: scroll;
    background-color: #f7f7f7;
    border-left: 1px solid #ccc;
}

.list-row-style {
    background-color: #818181;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
}
</style>
