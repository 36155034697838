
import { Vue, Options } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import PatientService from "../service/PatientService";
import Assessments from "../hospital_components/Assessments.vue";
import Consultation from "../hospital_components/Consultation.vue";
import Diagnosis from "../hospital_components/Diagnosis.vue";
import GeneralInfo from "../hospital_components/GeneralInfo.vue";
import Medications from "../hospital_components/Medications.vue";
import NextVisit from "../hospital_components/NextVisit.vue";
import Observations from "../hospital_components/Observations.vue";
import PatientDocs from "../hospital_components/PatientDocs.vue";
import Referral from "../hospital_components/Referral.vue";
import LabOrders from "../hospital_components/LabOrders.vue";
import OrderedLab from "../hospital_components/OrderedLab.vue";
import { useStore,ActionTypes } from "../store";
import moment from "moment";


@Options({
    emits: ["updateConfirmStatus"],
    components: {
        Assessments,
        Consultation,
        Diagnosis,
        GeneralInfo,
        Medications,
        NextVisit,
        Observations,
        PatientDocs,
        Referral,
        LabOrders,
        OrderedLab
    }
})
export default class PatientConsultancy extends Vue {
    private store = useStore();
    private activeTab = 0;
    private activeLabTab = 0;
    private patientService;
    private productDialog = true;
    private submitted = false;
    private dialogTitle = "";
    private toast;
    private vuexStore = useStore();

    created() {
        this.patientService = new PatientService();
        this.toast = new Toaster();
    }

    mounted()
    {
        this.loadList();
    }
    
    get progressBar() {
        return this.store.getters.getProgressBar;
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    closeDialog() {
        this.$emit("closeConsultantScreenEvent");
        this.productDialog = false;
        this.submitted = false;
    }

    get tabComponentName() {
        const tab = this.activeTab;
        let componentName = "";

        if (tab == 0) {
            componentName = "GeneralInfo";
        } else if (tab == 1) {
            componentName = "Assessments";
        } else if (tab == 2) {
            componentName = "Observations";
        } else if (tab == 3) {
            componentName = "Diagnosis";
        } else if (tab == 4) {
            componentName = "Consultation";
        } else if (tab == 5 && this.activeLabTab == 0) {
            componentName = "LabOrders";
        }else if (tab == 5 && this.activeLabTab == 1) {
            componentName = "OrderedLab";
        } else if (tab == 6) {
            componentName = "Medications";
        } else if (tab == 7) {
            componentName = "NextVisit";
        } else if (tab == 8) {
            componentName = "Referral";
        }
        else if (tab == 9) {
            componentName = "PatientDocs";
        }

        return componentName;
    }

    closeConsultantScreenEvent() {}

     patientAge(d) {
        let age = "";
        const dt = new Date(d);
        const years = moment().diff(dt, "years", false);
        age = years + " Year";

        if (years == 0) {
            const months = moment().diff(dt, "months", false);
            age = months + " Month";

            if (months == 0) {
                const days = moment().diff(dt, "days", false);
                age = days + " Day";
            }
        }

        return age;
    }

    loadList()
    {
        this.patientService.getItem(this.patientID).then(data => {
            const patientInfo = data[0];
            this.dialogTitle = patientInfo.first_name+' '+patientInfo.last_name+" | GENDER : "+patientInfo.gender+" | Age : "+this.patientAge(patientInfo.dob)+" | PHONE NO :  "+patientInfo.phone_no;
        });
    }
}
