
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import LabService from "../service/LabService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";
import ToggleButton from 'primevue/togglebutton';
import AutoComplete from "primevue/autocomplete";


interface OptionTypes {
    id: number;
    optionName: string;
}

interface LabTypes {
    charges: number;
    id: number;
    name: string;
    labCategory: OptionTypes
}


@Options({
    components: {
        Confirmation,
        ToggleButton,
        AutoComplete
    }
})
export default class LabOrders extends Vue {
    private catItems =   [];
    private labItems: LabTypes [] =  [];
    private chosenLabItems: LabTypes [] =  [];
    private lList: LabTypes [] =  [];
    private recordID = 0;
    private pService;
    private toast;
    private itemScanBox = "";
    private chosenCategory = {
        id: 0,
        optionName: ''
    };
    private componentName = "";
    private vuexStore = useStore();

    created() {
        this.pService = new LabService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    saveOrder() {
            this.pService.savePatientTest(this.chosenLabItems,this.patientID)
            .then(res => {
                this.clearItems();
                this.toast.handleResponse(res);
            });
    }

    clearItems() {
        this.chosenLabItems = [];
    }

    loadList() {
        this.pService.getALLCategories().then(data => {
            const res = this.camelizeKeys(data);
            this.catItems = res.categories;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
           this.chosenLabItems.splice(this.recordID,1);
        }
    }

    getItems()
    {
        this.pService.categoryList(this.chosenCategory.id).then(res => {
            const r = this.camelizeKeys(res.categories);
            this.labItems = r;
        });
    }

      searchLabTest(event) {
        setTimeout(() => {
            this.pService.searchLabTest(event.query.trim()).then(data => {
                this.lList = this.camelizeKeys(data.categories);
            });
        }, 200);
    }

    saveLabTest(event) {
        const itemInfo = event.value;
        this.chosenLabItems.push(itemInfo);   
        this.itemScanBox = '';
    }
}
