
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import PatientConsultationService from "../service/PatientConsultationService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";

interface recordTypes {
    pDate: Date;
}

@Options({
    components: {
        Confirmation
    }
})
export default class Assessments extends Vue {
    private recordList: recordTypes[] = [];
    private recordID = 0;
    private pService;
    private submitted = false;
    private toast;
    private componentName = "";
    private vuexStore = useStore();

    private state = reactive({
        weight: 0,
        height: 0,
        o2: 0,
        respiration: 0,
        temperature: 0,
        pressure: 0,
        pulse: 0
    });

    private validationRules = {
        weight: {
            required
        },
        height: {
            required
        },
        o2: {
            required
        },
        respiration: {
            required
        },
        temperature: {
            required
        },
        pressure: {
            required
        },
        pulse: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    created() {
        this.pService = new PatientConsultationService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            this.pService
                .saveAssessment(this.state, this.patientID)
                .then(res => {
                    this.clearItems();
                    this.toast.handleResponse(res);
                    this.loadList();
                });
        }
    }

    clearItems() {
        this.state.weight = 0;
        this.state.height = 0;
        this.state.o2 = 0;
        this.state.respiration = 0;
        this.state.temperature = 0;
        this.state.pressure = 0;
        this.state.pulse = 0;
    }

    loadList() {
        this.pService.getAssessment(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.records;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

     get sortedRecords() {
        let sortedList = {};

        this.recordList.forEach(e => {
            
            const d = moment().format("DD-MMM-YYYY");
            let date = moment(e.pDate).format("DD-MMM-YYYY");
            
            if (date == d) {
                date = "Today "+date;
            } 

           
            if (sortedList[date]) {
                sortedList[date].push(e);
            } else {
                sortedList[date] = [e];
            }
        });

        return sortedList;
    }

    get getTodaysDate()
    {
        return "Today " + moment().format("DD-MMM-YYYY");
    }

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.deleteAssessment(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }
}
