<template>
    <div class="p-grid">
        <h6 class="heading-style p-col-12 p-m-0">
            <i class="pi pi-check-circle"></i> Lab Orders <br />
            <small>Measure patients observations details and enter inside these fields</small>
        </h6>
        <div class="p-col-12 ">
            <div class="p-field p-my-0">
                <AutoComplete
                    :delay="1000"
                    :minLength="3"
                    @item-select="saveLabTest($event)"
                    scrollHeight="500px"
                    v-model="itemScanBox"
                    :suggestions="lList"
                    placeholder=" Search Lab Test"
                    @complete="searchLabTest($event)"
                    :dropdown="false"
                    autoFocus
                >
                    <template #item="slotProps">
                        <div>
                            <span class="p-mr-1">
                                TEST :
                                <b class="pull-right">
                                    {{
                                        slotProps.item.name.toUpperCase()
                                    }}
                                </b>
                            </span>
                        </div>
                        <div>
                            <small>
                                CATEGORY :
                                <b class="pull-right">
                                    {{  slotProps.item.labCategory.optionName }}
                                </b>
                            </small>
                        </div>
                    </template>
                </AutoComplete>
            </div>
        </div>
        <div class="p-col-2 category-item">
            <template v-for="i in catItems" :key="i">    
                <div class="p-field-checkbox">
                    <RadioButton  @change="getItems" :value="i" v-model="chosenCategory" :id="i.id" />
                    <label class="p-mt-2" :for="i.id">{{i.optionName}}</label>
                </div>
            </template>
        </div>
        <div class="p-col-8 mid-section-area">
            <template v-for="i in labItems" :key="i">    
                <div class="p-field-checkbox style-mid p-col-4">
                    <Checkbox :id="i.id" :value="i" v-model="chosenLabItems"/>
                    <label class="p-mt-2" :for="i.id">
                        {{i.name}}
                       <small> <br /> {{i.labCategory.optionName}} </small>
                     </label>
                </div>
            </template>
        </div>
        <div class="p-col-2">
            <template v-for="(l,i) in chosenLabItems" :key="l">
                <div class="p-col ordered-test">
                    <p class="p-d-flex p-jc-between p-m-0 p-p-0">
                        <small> SNO {{i+1}}</small>
                        <Button @click.prevent="deleteItem(i)" class="p-button-danger p-p-0" icon="pi pi-times" />
                    </p>
                    <p class="p-d-flex p-jc-between p-m-0 p-p-0">
                        <label>{{l.name}}</label>
                    </p>
                    <p class="p-d-flex p-jc-between p-m-0 p-p-0">
                       <small style="background-color:green; color:#fff;" class="p-px-2"> {{l.labCategory.optionName}}</small>
                    </p>
                </div>
            </template>
            <Button :disabled="chosenLabItems.length == 0" class="p-button-primary" @click="saveOrder()" icon="pi pi-check" label="Save"/>
        </div>
    </div>

    <component
        v-if="componentName != ''"
        v-bind:is="componentName"
        v-on:updateConfirmationStatus="updateConfirmation"
    >
    </component>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";
import { reactive } from "vue";
import LabService from "../service/LabService";
import Confirmation from "../components/Confirmation.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { camelCase } from "lodash";
import moment from "moment";
import ToggleButton from 'primevue/togglebutton';
import AutoComplete from "primevue/autocomplete";


interface OptionTypes {
    id: number;
    optionName: string;
}

interface LabTypes {
    charges: number;
    id: number;
    name: string;
    labCategory: OptionTypes
}


@Options({
    components: {
        Confirmation,
        ToggleButton,
        AutoComplete
    }
})
export default class LabOrders extends Vue {
    private catItems =   [];
    private labItems: LabTypes [] =  [];
    private chosenLabItems: LabTypes [] =  [];
    private lList: LabTypes [] =  [];
    private recordID = 0;
    private pService;
    private toast;
    private itemScanBox = "";
    private chosenCategory = {
        id: 0,
        optionName: ''
    };
    private componentName = "";
    private vuexStore = useStore();

    created() {
        this.pService = new LabService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    saveOrder() {
            this.pService.savePatientTest(this.chosenLabItems,this.patientID)
            .then(res => {
                this.clearItems();
                this.toast.handleResponse(res);
            });
    }

    clearItems() {
        this.chosenLabItems = [];
    }

    loadList() {
        this.pService.getALLCategories().then(data => {
            const res = this.camelizeKeys(data);
            this.catItems = res.categories;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
           this.chosenLabItems.splice(this.recordID,1);
        }
    }

    getItems()
    {
        this.pService.categoryList(this.chosenCategory.id).then(res => {
            const r = this.camelizeKeys(res.categories);
            this.labItems = r;
        });
    }

      searchLabTest(event) {
        setTimeout(() => {
            this.pService.searchLabTest(event.query.trim()).then(data => {
                this.lList = this.camelizeKeys(data.categories);
            });
        }, 200);
    }

    saveLabTest(event) {
        const itemInfo = event.value;
        this.chosenLabItems.push(itemInfo);   
        this.itemScanBox = '';
    }
}
</script>

<style scoped>
.heading-style {
    background-color: green;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
    border-radius: 2rem;
}

.sec-2-height {
    height: 75vh;
    min-height: 75vh;
    overflow-y: scroll;
    background-color: #f7f7f7;
    border-left: 1px solid #ccc;
}

.list-row-style {
    background-color: #818181;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
}

.category-item
{
    background-color: #eee;
}

.p-field-checkbox
{
    background-color: cadetblue;
    margin: 0;
    padding: 5px;
    margin-right:1px;
    margin-bottom:1px;
    color:#fff;
}

.style-mid
{
    margin: 0;
    padding: 5px;
    margin-right:1px;
    margin-bottom:1px;
    width: 32.95%;
    color:#fff;
    background-color: #6c757d;
    float: left;
}

.mid-section-area
{
    background-color: #f7f7f7;
}

.ordered-test
{
    background-color: #ffc107;
    border-bottom: 1px solid #eee;
    font-size: 16px;
}
</style>
