
import { Options, Vue } from "vue-class-component";
import { useStore } from "../store";
import { reactive } from "vue";
import Toaster from "../helpers/Toaster";
import PatientService from "../service/PatientService.js";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import moment from "moment";

@Options({
    inheritAttrs: false,
    emits: ["updatePatientStatus"]
})
export default class PatientDialog extends Vue {
    private toast;
    private submitted = false;
    private productDialog = true;
    private patientService;
    private vuexStore = useStore();

    private genderTypes = [
        { name: "Male" },
        { name: "Female" },
        { name: "Other" }
    ];

    private state = reactive({
        firstName: "",
        lastName: "",
        phoneNo: "",
        gender: "",
        dob: ""
    });

    private item = {
        id: 0
    };

    private validationRules = {
        firstName: {
            required
        },
        lastName: {
            required
        },
        phoneNo: {
            required
        },
        gender: {
            required
        },
        dob: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    //DEFAULT METHOD OF TYPE SCRIPT
    //CALLING WHENEVER COMPONENT LOADS
    created() {
        this.toast = new Toaster();
        this.patientService = new PatientService();
    }

    mounted() {
        this.loadPatient();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.productDialog = true;
    }

    closeDialog() {
        this.$emit("updatePatientStatus", {
            patientFullName: this.state.firstName + " " + this.state.lastName,
            patientID: this.item.id
        });
        this.productDialog = false;
        this.submitted = false;
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            if (this.patientID != "0") {
                this.patientService
                    .updateItem(this.patientID, this.state)
                    .then(res => {
                        this.closeDialog();
                        this.toast.handleResponse(res);
                    });
            } else {
                this.patientService.saveItem(this.state).then(res => {
                    if (res.patient_id != "") {
                        this.item.id = res.patient_id;
                        this.closeDialog();
                    }

                    this.toast.handleResponse(res);
                });
            }
        }
    }

    loadPatient() {
        this.patientService.getItem(this.patientID).then(res => {
            if (res != null) {
                this.item.id = res[0].id;
                this.state.firstName = res[0].first_name;
                this.state.lastName = res[0].last_name;
                this.state.phoneNo = res[0].phone_no;
                this.state.gender = res[0].gender;
                this.state.dob = this.formatDate(res[0].dob);
            }
        });
    }

    formatDate(d) {
        const dt = new Date(d);
        return moment(dt).format("YYYY-MM-DD");
    }

    get dialogTitle() {
        let title = "";

        if (this.patientID == "0") {
            title = "Register New Patient";
        } else {
            title = "Update Patient";
        }

        return title;
    }
}
