<template>
    <Dialog
        id="previewReceiptDailog"
        v-model:visible="productDialog"
        :style="{ width: '60vw' }"
        position="top"
        class="p-fluid"
        :modal="true"
        :closable="true"
        @hide="closeDialog"
    >
        <template #header>
            <h6 class="p-dialog-titlebar p-dialog-titlebar-icon">
                <i class="pi pi-plus-circle"></i> {{ dialogTitle }}
            </h6>
        </template>

        <div class="p-grid">
            <div class="p-col-6">
                <div class="p-field">
                    <label
                        for="firstName"
                        :class="{
                            'p-error': v$.firstName.$invalid && submitted
                        }"
                        >First Name</label
                    >
                    <InputText
                        id="firstName"
                        v-model="v$.firstName.$model"
                        :class="{
                            'p-invalid': v$.firstName.$invalid && submitted
                        }"
                    />
                    <small
                        v-if="
                            (v$.firstName.$invalid && submitted) ||
                                v$.firstName.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.firstName.required.$message.replace(
                                "Value",
                                "First Name"
                            )
                        }}</small
                    >
                </div>
            </div>
            <div class="p-col-6">
                <div class="p-field">
                    <label
                        for="firstName"
                        :class="{
                            'p-error': v$.lastName.$invalid && submitted
                        }"
                        >Last Name</label
                    >
                    <InputText
                        id="lastName"
                        v-model="v$.lastName.$model"
                        :class="{
                            'p-invalid': v$.lastName.$invalid && submitted
                        }"
                    />
                    <small
                        v-if="
                            (v$.lastName.$invalid && submitted) ||
                                v$.lastName.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.lastName.required.$message.replace(
                                "Value",
                                "Last Name"
                            )
                        }}</small
                    >
                </div>
            </div>
            <div class="p-col-6">
                <div class="p-field">
                    <label
                        for="gender"
                        :class="{ 'p-error': v$.gender.$invalid && submitted }"
                        >Gender</label
                    >
                    <Dropdown
                        id="gender"
                        v-model="v$.gender.$model"
                        :options="genderTypes"
                        optionLabel="name"
                        optionValue="name"
                    />
                    <small
                        v-if="
                            (v$.gender.$invalid && submitted) ||
                                v$.gender.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.gender.required.$message.replace(
                                "Value",
                                "Gender"
                            )
                        }}</small
                    >
                </div>
            </div>
            <div class="p-col-6">
                <div class="p-field">
                    <label
                        for="phoneNo"
                        :class="{ 'p-error': v$.phoneNo.$invalid && submitted }"
                        >Phone No</label
                    >
                    <InputText
                        id="phoneNo"
                        v-model="v$.phoneNo.$model"
                        :class="{
                            'p-invalid': v$.phoneNo.$invalid && submitted
                        }"
                    />
                    <small
                        v-if="
                            (v$.phoneNo.$invalid && submitted) ||
                                v$.phoneNo.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.phoneNo.required.$message.replace(
                                "Value",
                                "Phone No"
                            )
                        }}</small
                    >
                </div>
            </div>
            <div class="p-col-6">
                <div class="p-field">
                    <label
                        for="dob"
                        :class="{ 'p-error': v$.dob.$invalid && submitted }"
                        >Date of Birth</label
                    >
                    <Calendar
                        v-model="v$.dob.$model"
                        :class="{ 'p-invalid': v$.dob.$invalid && submitted }"
                        :touchUI="true"
                        dateFormat="dd-mm-yy"
                    />
                    <small
                        v-if="
                            (v$.dob.$invalid && submitted) ||
                                v$.dob.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.dob.required.$message.replace(
                                "Value",
                                "Date of Birth"
                            )
                        }}</small
                    >
                </div>
            </div>
        </div>

        <template #footer>
            <Button
                type="button"
                label="Save"
                icon="pi pi-check-circle"
                class="p-button-primary pull-left"
                @click.prevent="saveItem(!v$.$invalid)"
            />
        </template>
    </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useStore } from "../store";
import { reactive } from "vue";
import Toaster from "../helpers/Toaster";
import PatientService from "../service/PatientService.js";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import moment from "moment";

@Options({
    inheritAttrs: false,
    emits: ["updatePatientStatus"]
})
export default class PatientDialog extends Vue {
    private toast;
    private submitted = false;
    private productDialog = true;
    private patientService;
    private vuexStore = useStore();

    private genderTypes = [
        { name: "Male" },
        { name: "Female" },
        { name: "Other" }
    ];

    private state = reactive({
        firstName: "",
        lastName: "",
        phoneNo: "",
        gender: "",
        dob: ""
    });

    private item = {
        id: 0
    };

    private validationRules = {
        firstName: {
            required
        },
        lastName: {
            required
        },
        phoneNo: {
            required
        },
        gender: {
            required
        },
        dob: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    //DEFAULT METHOD OF TYPE SCRIPT
    //CALLING WHENEVER COMPONENT LOADS
    created() {
        this.toast = new Toaster();
        this.patientService = new PatientService();
    }

    mounted() {
        this.loadPatient();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.productDialog = true;
    }

    closeDialog() {
        this.$emit("updatePatientStatus", {
            patientFullName: this.state.firstName + " " + this.state.lastName,
            patientID: this.item.id
        });
        this.productDialog = false;
        this.submitted = false;
    }

    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            if (this.patientID != "0") {
                this.patientService
                    .updateItem(this.patientID, this.state)
                    .then(res => {
                        this.closeDialog();
                        this.toast.handleResponse(res);
                    });
            } else {
                this.patientService.saveItem(this.state).then(res => {
                    if (res.patient_id != "") {
                        this.item.id = res.patient_id;
                        this.closeDialog();
                    }

                    this.toast.handleResponse(res);
                });
            }
        }
    }

    loadPatient() {
        this.patientService.getItem(this.patientID).then(res => {
            if (res != null) {
                this.item.id = res[0].id;
                this.state.firstName = res[0].first_name;
                this.state.lastName = res[0].last_name;
                this.state.phoneNo = res[0].phone_no;
                this.state.gender = res[0].gender;
                this.state.dob = this.formatDate(res[0].dob);
            }
        });
    }

    formatDate(d) {
        const dt = new Date(d);
        return moment(dt).format("YYYY-MM-DD");
    }

    get dialogTitle() {
        let title = "";

        if (this.patientID == "0") {
            title = "Register New Patient";
        } else {
            title = "Update Patient";
        }

        return title;
    }
}
</script>
