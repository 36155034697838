<template>
    <section>
        <div class="app-container">
            <Toolbar>
                <template #start>
                    <Breadcrumb
                        :home="home"
                        :model="items"
                        class="p-menuitem-text"
                    />
                </template>

                <template #end>
                    <div class="p-mx-2">
                        <Dropdown
                            :filter="true"
                            style="width: 15rem"
                            v-model="selectedOption"
                            :options="optionList"
                            optionLabel="optionName"
                            placeholder="Choose lab categories"
                            optionValue="id"
                            @change="loadList(0)"
                        />
                    </div>
                    <div class="p-mx-2">
                        <Button
                            icon="pi pi-plus"
                            class="p-button-success"
                            @click="openDialog"
                        />
                    </div>
                </template>
            </Toolbar>
            <div class="p-mt-2">
                <DataTable
                    v-model:first.sync="goToFirstLink"
                    :value="lists"
                    :lazy="true"
                    :paginator="checkPagination"
                    :rows="limit"
                    :totalRecords="totalRecords"
                    :scrollable="true"
                    @page="onPage($event)"
                    class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
                >
                    <template #empty>
                        <div class="p-text-center p-p-3">No records found</div>
                    </template>
                    <Column
                        field="lab_category.option_name"
                        header="Category Name"
                    ></Column>
                    <Column field="name" header="Test Name"></Column>
                    <Column field="charges" header="Charges"></Column>
                    <Column :exportable="false" header="Action">
                        <template #body="slotProps">
                            <Button
                                icon="pi pi-pencil"
                                class="p-button-rounded p-button-primary p-mr-2"
                                @click="editIem(slotProps.data)"
                            />
                            <Button
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-danger"
                                @click="confirmChangeStatus(slotProps.data)"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <Dialog
                v-model:visible="productDialog"
                :style="{ width: '50vw' }"
                :maximizable="true"
                position="top"
                class="p-fluid"
            >
                <template #header>
                    <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
                        {{ dialogTitle }}
                    </h5>
                </template>
                <div class="p-field">
                    <label
                        for="testName"
                        :class="{
                            'p-error': v$.testName.$invalid && submitted
                        }"
                        >Test Name</label
                    >
                    <InputText
                        id="testName"
                        v-model="v$.testName.$model"
                        :class="{
                            'p-invalid': v$.testName.$invalid && submitted
                        }"
                        autoFocus
                    />
                    <small
                        v-if="
                            (v$.testName.$invalid && submitted) ||
                                v$.testName.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.testName.required.$message.replace(
                                "Value",
                                "Test Name"
                            )
                        }}</small
                    >
                </div>
                <div class="p-field">
                    <label
                        for="categoryType"
                        :class="{
                            'p-error': v$.categoryType.$invalid && submitted
                        }"
                        >Category Type</label
                    >
                    <Dropdown
                        id="categoryType"
                        v-model="v$.categoryType.$model"
                        :options="optionList"
                        optionLabel="optionName"
                    />
                    <small
                        v-if="
                            (v$.categoryType.$invalid && submitted) ||
                                v$.categoryType.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.categoryType.required.$message.replace(
                                "Value",
                                "Category Type"
                            )
                        }}</small
                    >
                </div>
                <div class="p-field">
                    <label
                        for="charges"
                        :class="{ 'p-error': v$.charges.$invalid && submitted }"
                        >Charges</label
                    >
                    <InputNumber
                        :maxFractionDigits="2"
                        :minFractionDigits="2"
                        id="charges"
                        v-model="v$.charges.$model"
                        :class="{
                            'p-invalid': v$.charges.$invalid && submitted
                        }"
                    />
                    <small
                        v-if="
                            (v$.charges.$invalid && submitted) ||
                                v$.charges.$pending.$response
                        "
                        class="p-error"
                        >{{
                            v$.charges.required.$message.replace(
                                "Value",
                                "Charges"
                            )
                        }}</small
                    >
                </div>

                <template #footer>
                    <Button
                        type="submit"
                        label="Save"
                        icon="pi pi-check"
                        class="p-button-primary"
                        @click.prevent="saveItem(!v$.$invalid)"
                    />
                </template>
            </Dialog>

            <Dialog
                v-model:visible="statusDialog"
                :style="{ width: '450px' }"
                header="Confirm"
            >
                <div class="confirmation-content">
                    <i
                        class="pi pi-exclamation-triangle p-mr-3"
                        style="font-size: 2rem"
                    />
                    <span
                        >Are you sure to delete
                        <b>{{ state.testName }}</b> ?</span
                    >
                </div>
                <template #footer>
                    <Button
                        label="No"
                        icon="pi pi-times"
                        class="p-button-success"
                        @click="statusDialog = false"
                    />
                    <Button
                        label="Yes"
                        icon="pi pi-check"
                        class="p-button-danger"
                        @click="changeStatus"
                    />
                </template>
            </Dialog>
        </div>
    </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import LabService from "../../service/LabService.js";
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import Toaster from "../../helpers/Toaster";

interface optionListType {
    optionName: string;
    id: number;
}

@Options({
    components: {}
})
export default class LabList extends Vue {
    private lists = [];
    private dialogTitle;
    private keyword = "";
    private toast;
    private goToFirstLink = 0;
    private labService;
    private productDialog = false;
    private submitted = false;
    private statusDialog = false;
    private checkPagination = true;
    private totalRecords = 0;
    private limit = 0;
    private home = { icon: "pi pi-home", to: "/" };
    private optionList: optionListType[] = [];
    private items = [
        { label: "Initialization", to: "initialization" },
        { label: "Laboratory Tests" }
    ];

    private item = {
        id: 0,
        status: "Active"
    };

    private selectedOption = 0;

    private state = reactive({
        testName: "",
        categoryType: { id: 0, optionName: "" },
        charges: 0
    });

    private validationRules = {
        testName: {
            required
        },
        categoryType: {
            required
        },
        charges: {
            required
        }
    };

    private v$ = useVuelidate(this.validationRules, this.state);

    //CALLING WHEN PAGINATION BUTTON CLICKS
    onPage(event) {
        this.loadList(event.first);
    }

    //DEFAULT METHOD OF TYPE SCRIPT
    created() {
        this.labService = new LabService();
        this.toast = new Toaster();
    }

    //CALLNING AFTER CONSTRUCTOR GET CALLED
    mounted() {
        this.loadList(0);
    }

    //OPEN DIALOG TO ADD NEW ITEM
    openDialog() {
        this.clearItem();

        this.submitted = false;
        this.dialogTitle = "Add New Test";
        this.productDialog = true;
    }

    //CLOSE THE ITEM DAILOG BOX
    hideDialog() {
        this.productDialog = false;
        this.submitted = false;
    }

    //ADD OR UPDATE THE ITEM VIA HTTP
    saveItem(isFormValid) {
        this.submitted = true;
        if (isFormValid) {
            if (this.item.id != 0) {
                this.labService.updateItem(this.item, this.state).then(res => {
                    this.loadList(this.goToFirstLink);
                    //SHOW NOTIFICATION
                    this.toast.handleResponse(res);
                });
            } else {
                this.labService.saveItem(this.item, this.state).then(res => {
                    this.goToFirstLink = 0;
                    this.loadList(this.goToFirstLink);
                    //SHOW NOTIFICATION
                    this.toast.handleResponse(res);
                });
            }

            this.productDialog = false;
            this.clearItem();
        }
    }

    //OPEN DIALOG BOX TO EDIT
    editIem(data) {
        this.submitted = false;
        this.dialogTitle = "Update Test";
        this.productDialog = true;

        this.labService.getItem(data).then(res => {
            if (res.length > 0) {
                const charges = Number(res[0].charges);
                this.state.testName = res[0].name;
                this.state.charges = charges;
                this.state.categoryType = this.searchOption(res[0].type_id);
                this.item.status = res[0].status;
                this.item.id = res[0].id;
            }
        });
    }

    searchOption(id) {
        let obj = {
            id: 0,
            optionName: ""
        };

        this.optionList.forEach(e => {
            if (e.id == id) {
                obj = e;
            }
        });
        return obj;
    }

    //OPEN DIALOG BOX FOR CONFIRMATION
    confirmChangeStatus(data) {
        this.item.id = data.id;
        this.state.testName = data.name;
        this.statusDialog = true;
    }

    //CHANGE THE STATUS AND SEND HTTP TO SERVER
    changeStatus() {
        this.statusDialog = false;
        this.item.status = "Delete";
        this.labService.changeStatus(this.item).then(res => {
            this.loadList(0);
            //SHOW NOTIFICATION
            this.toast.handleResponse(res);
        });
    }

    //FETCH THE DATA FROM SERVER
    loadList(page) {
        this.labService.getItems(this.selectedOption, page).then(data => {
            this.lists = data.records;
            this.totalRecords = data.totalRecords;
            const optionList = data.categories;
            this.limit = data.limit;

            optionList.forEach(e => {
                this.optionList.push({
                    optionName: e.option_name,
                    id: e.id
                });
            });
        });
    }

    clearItem() {
        this.item = {
            id: 0,
            status: "Active"
        };

        this.state.testName = "";
        this.state.categoryType = { id: 0, optionName: "" };
        this.state.charges = 0;
    }
}
</script>
