<template>
    <div class="p-grid">
        <h6 class="heading-style p-col-12 p-m-0">
            <i class="pi pi-check-circle"></i> Requested Lab Orders <br />
            <small>Measure patients observations details and enter inside these fields</small>
        </h6>
        <div class="p-col-12 ">
           <DataTable
                :value="recordList"
                :scrollable="true"
                class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
            >
                <template #empty>
                    <div class="p-text-center p-p-3">No records found</div>
                </template>
                <Column field="" header="Patient Name">
                    <template #body="slotProps">
                        {{slotProps.data.patientInfo.firstName}} {{slotProps.data.patientInfo.lastName}}
                    </template>
                </Column>
                <Column field="patientInfo.phoneNo" header="Patient Phone"></Column>
                <Column field="optionName" header="Panel Name"></Column>
                <Column field="testName" header="Test Name"></Column>
                <Column field="charges" header="Charges"></Column>
                <Column field="assignStatus" header="Status"></Column>
                <Column field="userInfo.name" header="Created By"></Column>
                <Column :exportable="false" header="Action">
                    <template #body="slotProps">
                        <Button
                            :disabled="slotProps.data.assignStatus != 'Not Assign'"
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-danger"
                            @click="deleteItem(slotProps.data.id)"
                        />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <component
        v-if="componentName != ''"
        v-bind:is="componentName"
        v-on:updateConfirmationStatus="updateConfirmation"
    >
    </component>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";import LabService from "../service/LabService";
import Confirmation from "../components/Confirmation.vue";
import { camelCase } from "lodash";

@Options({
    components: {
        Confirmation
    }
})
export default class OrderedLab extends Vue {
    private recordList = [];
    private recordID = 0;
    private pService;
    private toast;
    private vuexStore = useStore();
    private componentName = "";


    created() {
        this.pService = new LabService();
        this.toast = new Toaster();
    }

    mounted() {
        this.loadList();
    }

    get patientID() {
        return this.vuexStore.getters.getReceiptID;
    }


    loadList() {
        this.pService.getOrderedRequests(this.patientID).then(data => {
            const res = this.camelizeKeys(data);
            this.recordList = res.record;
        });
    }

    camelizeKeys = obj => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key])
                }),
                {}
            );
        }
        return obj;
    };

   

    deleteItem(id) {
        this.vuexStore.dispatch(
            ActionTypes.GET_RECEIPT_TITLE,
            "Are you sure to delete this"
        );
        this.componentName = "Confirmation";
        this.recordID = id;
    }

    updateConfirmation(b) {
        this.componentName = "";
        if (b.result) {
            this.pService.deleteOrderedRequest(this.recordID).then(res => {
                this.toast.handleResponse(res);
                this.loadList();
            });
        }
    }
}
</script>

<style scoped>
.heading-style {
    background-color: green;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
    border-radius: 2rem;
}

.sec-2-height {
    height: 75vh;
    min-height: 75vh;
    overflow-y: scroll;
    background-color: #f7f7f7;
    border-left: 1px solid #ccc;
}

.list-row-style {
    background-color: #818181;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0;
}

.category-item
{
    background-color: #eee;
}

.p-field-checkbox
{
    background-color: cadetblue;
    margin: 0;
    padding: 5px;
    margin-right:1px;
    margin-bottom:1px;
    color:#fff;
}

.style-mid
{
    margin: 0;
    padding: 5px;
    margin-right:1px;
    margin-bottom:1px;
    width: 32.95%;
    color:#fff;
    background-color: #6c757d;
    float: left;
}

.mid-section-area
{
    background-color: #f7f7f7;
}

.ordered-test
{
    background-color: #ffc107;
    border-bottom: 1px solid #eee;
    font-size: 16px;
}
</style>
